.team-social-icons {
	&.fa::before {
		color: #fff;
	}
	&.fa-instagram {
		background: #5e5e5e;
		-webkit-text-fill-color: #fff;
	}

	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 35px;
	text-align: center;
	border-radius: 3px 0 0 3px;
	background-color: #5e5e5e;
}

.team-edit-icon {
	width: 20px;
	height: 20px;
	line-height: 1.3;
	text-align: center;
	background-color: #5e5e5e;
	&::before {
		font-size: 11px;
	}
}

.card .row-striped:nth-of-type(even) {
	background-color: #efefef;
}
