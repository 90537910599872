.sortable-list-table-row {
	min-width: 125% !important;
	@media screen and (max-width: 540px) {
		min-width: 130% !important;
	}
	@media screen and (max-width: 475px) {
		min-width: 135% !important;
	}
	@media screen and (max-width: 400px) {
		min-width: 145% !important;
	}
	@media screen and (max-width: 320px) {
		min-width: 125% !important;
	}
}

.span {
	position: absolute;
	opacity: 0;
	z-index: -100;
	white-space: pre;
}

.index-input {
	min-width: 30px;
	height: auto;
	text-align: center;
	padding: 2px;
}
