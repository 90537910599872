@import '../../../scss/custom/media_query';

$toolbarWidth: 350px;
$saveButtonWidth: 70px;
$borderColor: rgb(228, 226, 228);
$fontColor: #353a48;
$backgroundColor: #f0f3f5;

.editor-toolbar-container {
	position: fixed;
	bottom: 30px;
	right: 65px;
	z-index: 2;
	display: flex;
	box-shadow: 2px 3px rgba(35, 40, 44, 0.2);

	.editor-toggle-btn {
		height: 48px;

		button {
			border: none;
			border-radius: unset;
			background-color: $backgroundColor;
			color: $fontColor;

			svg {
				color: $fontColor;
			}

			&:hover:enabled {
				background-color: $borderColor;
			}
		}

		.btn-secondary:focus {
			box-shadow: none;
		}
	}
}

.editor-button-toolbar {
	max-width: $toolbarWidth;
	bottom: 30px;
	right: 65px;
	z-index: 2;
	border: 1px solid $borderColor;
	border-right: none;
	border-bottom: none;
	overflow: hidden;
	background-color: $backgroundColor;
	border-radius: 5px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	padding: 7px;

	.btn-group {
		border: none;
		border-radius: unset;
		border-right: 1px solid $borderColor;

		button {
			border: none;
			border-radius: unset;
			background-color: $backgroundColor;
			color: $fontColor;

			svg {
				color: $fontColor;
			}

			&:hover:enabled {
				background-color: $borderColor;
			}
		}

		&:last-child {
			border-right: none;
			width: $saveButtonWidth;
			button {
				color: white;
				background-color: #039be5;
				border-radius: 5px;

				&:hover:enabled {
					background-color: #0381bf;
				}
			}
		}
	}

	.editor-toolbar-tooltip {
		.tooltip {
			background-color: transparent !important;
			width: 300px;
			opacity: 1;
			margin: 0 12px 0 0;
		}
		.tooltip-inner {
			color: #000000;
			padding: 0;
			margin: 0;
			background-color: #fff;
		}
	}

	.card {
		margin-bottom: 0;
		border: 0;

		.card-body {
			padding: 0;
		}
	}
}

@include phone {
	.editor-toolbar-container {
		height: 35px;
		right: 30px;

		.editor-toggle-btn button {
			height: 35px;
			background-color: #f0f3f5;
		}
	}
	.editor-button-toolbar {
		max-width: unset;
		width: 100%;
		height: 50px;
		bottom: 30px;
		justify-content: center;
		border: none;
		background-color: unset;
		overflow: visible;
		padding: 0;

		.btn-group {
			border: 1px solid $borderColor;
			&:last-child {
				button {
					border-radius: unset;
				}
			}
		}
	}
}
