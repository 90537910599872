@import "../general/export.scss";

.disclaimer_message_container {
  padding: 0 16px;
  .domain_disclaimer_message {
    @include OpenSansFont(400, 20px, 14px);
    text-align: center;
  }
}

