.source-edit-btn,
.source-save-btn {
	min-width: 36px;
	margin-left: auto;
	padding: 8px 0;
	margin-top: 13px !important;
}

.source-delete-btn {
	min-width: 36px;
	padding: 8px 0;
	margin-left: 4px;
	margin-top: 13px !important;
}

.form-group-container {
	flex-direction: row !important;
	margin-left: 0;
}

.form-control:disabled {
	background-color: rgb(228, 231, 234);
	opacity: 0.5;
}

.source-form-control {
	max-width: 700px;
}

.source-feed-thumbnail {
	min-width: 40px;
	margin: 20px 5px 10px !important;
}

@media screen and (max-width: 600px) {
	.form-group-container {
		flex-direction: column !important;
	}

	.source-container {
		flex-direction: column;
	}

	.form-group {
		width: 100% !important;
	}
}
