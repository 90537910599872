.category-listing-container-permissions {
	.card-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	table {
		tr:nth-child(even) {
			background-color: #f2f2f2;
		}
		tr {
			cursor: pointer;
			&:hover {
				background-color: rgba(11, 167, 239, 0.23);
			}
		}
		.selected {
			background-color: #0ba7ef !important;
		}
	}

	.permissions-col-container {
		width: 100%;
	}

	.header-category-perms {
		padding: 15px;
		font-size: 14px;
		border-right: 1px solid #c8ced2;
		border-bottom: 1px solid #c8ced2;
		font-weight: bold;
		/* offset-x | offset-y | blur-radius | color */
		box-shadow: 0 3px 6px #c8ced2;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.check-all-categories {
		width: 150px;
		input {
			margin-left: 10px;
		}

		label {
			padding-top: 8px;
			cursor: pointer;
		}
	}

	.users-container {
		height: 700px;
		overflow: scroll;
		border-right: 1px solid #c8ced2;
	}

	.categories-container {
		width: 100%;
		height: 700px;
		overflow: scroll;
		border-right: 1px solid #c8ced2;

		table {
			tr {
				width: 100%;
			}
		}
	}
}
