.start-at-time-picker-container {
	.date-time-picker-container {
		position: relative;
		.date-picker {
			width: 100px;
			display: inline-block;
		}

		.clear-button {
			position: absolute;
			display: inline-block;
			top: 20%;
			margin-left: -20px;
			cursor: pointer;
		}
	}
}

.matches-search-toggle {
	margin: 6px 0 0 10px;
}
