.copy-to-projects-modal {
	.table-row {
		border-top: 1px solid #c8ced3;
		&:nth-child(even) {
			background-color: #f4f4f4;
		}

		.table-col {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 0 !important;
			cursor: pointer;
		}
	}
}
