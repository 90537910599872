.form-check-input-override {
	margin-bottom: 0;
	.form-check-input {
		position: static;
		margin-top: 0;
		margin-left: 0;
		margin-bottom: 5px;
	}

	label {
		margin-bottom: 0;
	}
}
