$minute-width: 35px;
$menu-width: 16px;
$gap: 8px;
$sponsor-logo-size: 100px;

.live-blog-post {
	display: grid;
	grid-template-columns: 1fr $menu-width;
	grid-template-rows: repeat(2, auto);
	gap: $gap;
	border: 1px solid lightgray;
	border-radius: 8px;
	padding: $gap;

	grid-template-areas:
		'content menuButton'
		'timestamp menuButton';

	&:not(:last-child) {
		margin-bottom: 16px;
	}

	&.highlighted {
		background-color: #f1f5f9;
	}

	&.locked {
		.live-blog-post-minute,
		.live-blog-post-author,
		.live-blog-post-content,
		.live-blog-post-sponsor-logos,
		.live-blog-post-timestamp,
		.live-blog-post-automatic-image {
			filter: blur(1px);
		}
	}

	&.with-author {
		grid-template-rows: repeat(3, auto);
		grid-template-areas:
			'author menuButton'
			'content menuButton'
			'timestamp menuButton';
	}

	&.with-minute {
		grid-template-columns: $minute-width 1fr $menu-width;
		grid-template-areas:
			'minute content menuButton'
			'minute timestamp menuButton';

		&.with-author {
			grid-template-areas:
				'minute author menuButton'
				'minute content menuButton'
				'minute timestamp menuButton';
		}
	}

	&.with-sponsors {
		grid-template-areas:
			'content menuButton'
			'sponsor-logos menuButton'
			'timestamp menuButton';

		&.with-author {
			grid-template-rows: repeat(3, auto);
			grid-template-areas:
				'author menuButton'
				'content menuButton'
				'sponsor-logos menuButton'
				'timestamp menuButton';
		}

		&.with-minute {
			grid-template-columns: $minute-width 1fr $menu-width;
			grid-template-areas:
				'minute content menuButton'
				'minute sponsor-logos menuButton'
				'minute timestamp menuButton';

			&.with-author {
				grid-template-areas:
					'minute author menuButton'
					'minute content menuButton'
					'minute sponsor-logos menuButton'
					'minute timestamp menuButton';
			}
		}
	}

	&.automatic-with-image {
		grid-template-columns: $minute-width 1fr $menu-width;
		grid-template-areas:
			'automatic-image content menuButton'
			'automatic-image timestamp menuButton';

		&.with-author {
			grid-template-rows: repeat(3, auto);
			grid-template-areas:
				'automatic-image author menuButton'
				'automatic-image content menuButton'
				'automatic-image timestamp menuButton';
		}

		&.with-minute {
			grid-template-columns: $minute-width 1fr $menu-width;
			grid-template-rows: repeat(3, auto);
			grid-template-areas:
				'minute content menuButton'
				'automatic-image content menuButton'
				'automatic-image timestamp menuButton';

			&.with-author {
				grid-template-areas:
					'minute author menuButton'
					'automatic-image content menuButton'
					'automatic-image timestamp menuButton';
			}
		}

		&.with-sponsors {
			grid-template-areas:
				'automatic-image content menuButton'
				'automatic-image sponsor-logos menuButton'
				'automatic-image timestamp menuButton';

			&.with-author {
				grid-template-rows: repeat(3, auto);
				grid-template-areas:
					'automatic-image author menuButton'
					'automatic-image content menuButton'
					'automatic-image sponsor-logos menuButton'
					'automatic-image timestamp menuButton';
			}

			&.with-minute {
				grid-template-columns: $minute-width 1fr $menu-width;
				grid-template-areas:
					'minute content menuButton'
					'automatic-image content menuButton'
					'automatic-image sponsor-logos menuButton'
					'automatic-image timestamp menuButton';

				&.with-author {
					grid-template-areas:
						'minute author menuButton'
						'automatic-image content menuButton'
						'automatic-image sponsor-logos menuButton'
						'automatic-image timestamp menuButton';
				}
			}
		}
	}

	&.to-delete {
		display: none;
	}

	.live-blog-post-minute {
		grid-area: minute;
		justify-self: center;
		font-size: 16px;

		&.with-injury {
			font-size: 14px;
		}
	}

	.live-blog-post-automatic-image {
		grid-area: automatic-image;
		display: flex;
		justify-content: center;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: 24px 24px;
	}

	.live-blog-post-author {
		grid-area: author;
		font-size: 16px;
		font-weight: bold;
	}

	.live-blog-post-content {
		grid-area: content;

		.ProseMirror {
			border: none;
			padding: 0;
		}
	}

	.live-blog-post-sponsor-logos {
		grid-area: sponsor-logos;
		display: grid;
		grid-template-columns: repeat(auto-fill, $sponsor-logo-size);
		gap: 4px;

		.live-blog-post-sponsor-logo {
			width: $sponsor-logo-size;
			height: calc(#{$sponsor-logo-size} / 5);
			background-size: contain;
			background-repeat: no-repeat;
		}
	}

	.live-blog-post-timestamp {
		grid-area: timestamp;
		color: gray;

		.fa {
			margin-right: 8px;
		}
	}

	.live-blog-post-menu-button {
		grid-area: menuButton;
		height: 24px;
		text-align: center;
		font-size: 18px;
		cursor: pointer;
	}
}

.live-blog-post-menu .popover {
	z-index: 1111;

	.popover-body {
		display: flex;
		flex-direction: column;

		& > button {
			display: flex;
			align-items: center;
			border: none;

			& > i {
				margin-right: 8px;
			}
		}
	}
}
