.#{$fa-css-prefix}.#{$fa-css-prefix}-glass:before {
	content: unquote('"#{ $fa-var-martini-glass-empty }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-envelope-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-envelope-o:before {
	content: unquote('"#{ $fa-var-envelope }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-star-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-star-o:before {
	content: unquote('"#{ $fa-var-star }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-remove:before {
	content: unquote('"#{ $fa-var-xmark }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-close:before {
	content: unquote('"#{ $fa-var-xmark }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gear:before {
	content: unquote('"#{ $fa-var-gear }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-trash-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-trash-o:before {
	content: unquote('"#{ $fa-var-trash-can }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-home:before {
	content: unquote('"#{ $fa-var-house }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-o:before {
	content: unquote('"#{ $fa-var-file }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-clock-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-clock-o:before {
	content: unquote('"#{ $fa-var-clock }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-down {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-down:before {
	content: unquote('"#{ $fa-var-circle-down }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-up {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-up:before {
	content: unquote('"#{ $fa-var-circle-up }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-play-circle-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-play-circle-o:before {
	content: unquote('"#{ $fa-var-circle-play }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-repeat:before {
	content: unquote('"#{ $fa-var-arrow-rotate-right }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rotate-right:before {
	content: unquote('"#{ $fa-var-arrow-rotate-right }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-refresh:before {
	content: unquote('"#{ $fa-var-arrows-rotate }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-list-alt {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-list-alt:before {
	content: unquote('"#{ $fa-var-rectangle-list }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dedent:before {
	content: unquote('"#{ $fa-var-outdent }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-video-camera:before {
	content: unquote('"#{ $fa-var-video }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-picture-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-picture-o:before {
	content: unquote('"#{ $fa-var-image }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-photo {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-photo:before {
	content: unquote('"#{ $fa-var-image }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-image {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-image:before {
	content: unquote('"#{ $fa-var-image }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-map-marker:before {
	content: unquote('"#{ $fa-var-location-dot }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pencil-square-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-pencil-square-o:before {
	content: unquote('"#{ $fa-var-pen-to-square }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-edit {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-edit:before {
	content: unquote('"#{ $fa-var-pen-to-square }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-share-square-o:before {
	content: unquote('"#{ $fa-var-share-from-square }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-check-square-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-check-square-o:before {
	content: unquote('"#{ $fa-var-square-check }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrows:before {
	content: unquote('"#{ $fa-var-up-down-left-right }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-times-circle-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-times-circle-o:before {
	content: unquote('"#{ $fa-var-circle-xmark }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-check-circle-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-check-circle-o:before {
	content: unquote('"#{ $fa-var-circle-check }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mail-forward:before {
	content: unquote('"#{ $fa-var-share }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-expand:before {
	content: unquote('"#{ $fa-var-up-right-and-down-left-from-center }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-compress:before {
	content: unquote('"#{ $fa-var-down-left-and-up-right-to-center }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-eye {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-eye-slash {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-warning:before {
	content: unquote('"#{ $fa-var-triangle-exclamation }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar:before {
	content: unquote('"#{ $fa-var-calendar-days }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrows-v:before {
	content: unquote('"#{ $fa-var-up-down }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrows-h:before {
	content: unquote('"#{ $fa-var-left-right }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bar-chart:before {
	content: unquote('"#{ $fa-var-chart-column }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bar-chart-o:before {
	content: unquote('"#{ $fa-var-chart-column }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-twitter-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gears:before {
	content: unquote('"#{ $fa-var-gears }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thumbs-o-up {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-thumbs-o-up:before {
	content: unquote('"#{ $fa-var-thumbs-up }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thumbs-o-down {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-thumbs-o-down:before {
	content: unquote('"#{ $fa-var-thumbs-down }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-heart-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-heart-o:before {
	content: unquote('"#{ $fa-var-heart }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sign-out:before {
	content: unquote('"#{ $fa-var-right-from-bracket }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-linkedin-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-linkedin-square:before {
	content: unquote('"#{ $fa-var-linkedin }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thumb-tack:before {
	content: unquote('"#{ $fa-var-thumbtack }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-external-link:before {
	content: unquote('"#{ $fa-var-up-right-from-square }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sign-in:before {
	content: unquote('"#{ $fa-var-right-to-bracket }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-github-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-lemon-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-lemon-o:before {
	content: unquote('"#{ $fa-var-lemon }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-square-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-square-o:before {
	content: unquote('"#{ $fa-var-square }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bookmark-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bookmark-o:before {
	content: unquote('"#{ $fa-var-bookmark }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-twitter {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook:before {
	content: unquote('"#{ $fa-var-facebook-f }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook-f {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook-f:before {
	content: unquote('"#{ $fa-var-facebook-f }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-github {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-credit-card {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-feed:before {
	content: unquote('"#{ $fa-var-rss }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hdd-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hdd-o:before {
	content: unquote('"#{ $fa-var-hard-drive }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-right {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-right:before {
	content: unquote('"#{ $fa-var-hand-point-right }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-left {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-left:before {
	content: unquote('"#{ $fa-var-hand-point-left }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-up {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-up:before {
	content: unquote('"#{ $fa-var-hand-point-up }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-down {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-o-down:before {
	content: unquote('"#{ $fa-var-hand-point-down }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-globe:before {
	content: unquote('"#{ $fa-var-earth-americas }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tasks:before {
	content: unquote('"#{ $fa-var-bars-progress }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrows-alt:before {
	content: unquote('"#{ $fa-var-maximize }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-group:before {
	content: unquote('"#{ $fa-var-users }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-chain:before {
	content: unquote('"#{ $fa-var-link }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cut:before {
	content: unquote('"#{ $fa-var-scissors }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-files-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-files-o:before {
	content: unquote('"#{ $fa-var-copy }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-floppy-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-floppy-o:before {
	content: unquote('"#{ $fa-var-floppy-disk }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-save {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-save:before {
	content: unquote('"#{ $fa-var-floppy-disk }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-navicon:before {
	content: unquote('"#{ $fa-var-bars }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-reorder:before {
	content: unquote('"#{ $fa-var-bars }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-magic:before {
	content: unquote('"#{ $fa-var-wand-magic-sparkles }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pinterest {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pinterest-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus:before {
	content: unquote('"#{ $fa-var-google-plus-g }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-money:before {
	content: unquote('"#{ $fa-var-money-bill-1 }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-unsorted:before {
	content: unquote('"#{ $fa-var-sort }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-desc:before {
	content: unquote('"#{ $fa-var-sort-down }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-asc:before {
	content: unquote('"#{ $fa-var-sort-up }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-linkedin {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-linkedin:before {
	content: unquote('"#{ $fa-var-linkedin-in }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rotate-left:before {
	content: unquote('"#{ $fa-var-arrow-rotate-left }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-legal:before {
	content: unquote('"#{ $fa-var-gavel }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tachometer:before {
	content: unquote('"#{ $fa-var-gauge-high }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dashboard:before {
	content: unquote('"#{ $fa-var-gauge-high }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-comment-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-comment-o:before {
	content: unquote('"#{ $fa-var-comment }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-comments-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-comments-o:before {
	content: unquote('"#{ $fa-var-comments }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-flash:before {
	content: unquote('"#{ $fa-var-bolt }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-clipboard:before {
	content: unquote('"#{ $fa-var-paste }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-lightbulb-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-lightbulb-o:before {
	content: unquote('"#{ $fa-var-lightbulb }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-exchange:before {
	content: unquote('"#{ $fa-var-right-left }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cloud-download:before {
	content: unquote('"#{ $fa-var-cloud-arrow-down }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cloud-upload:before {
	content: unquote('"#{ $fa-var-cloud-arrow-up }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bell-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bell-o:before {
	content: unquote('"#{ $fa-var-bell }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cutlery:before {
	content: unquote('"#{ $fa-var-utensils }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-text-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-text-o:before {
	content: unquote('"#{ $fa-var-file-lines }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-building-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-building-o:before {
	content: unquote('"#{ $fa-var-building }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hospital-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hospital-o:before {
	content: unquote('"#{ $fa-var-hospital }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tablet:before {
	content: unquote('"#{ $fa-var-tablet-screen-button }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mobile:before {
	content: unquote('"#{ $fa-var-mobile-screen-button }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mobile-phone:before {
	content: unquote('"#{ $fa-var-mobile-screen-button }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-circle-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-circle-o:before {
	content: unquote('"#{ $fa-var-circle }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mail-reply:before {
	content: unquote('"#{ $fa-var-reply }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-github-alt {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-folder-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-folder-o:before {
	content: unquote('"#{ $fa-var-folder }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-folder-open-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-folder-open-o:before {
	content: unquote('"#{ $fa-var-folder-open }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-smile-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-smile-o:before {
	content: unquote('"#{ $fa-var-face-smile }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-frown-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-frown-o:before {
	content: unquote('"#{ $fa-var-face-frown }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-meh-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-meh-o:before {
	content: unquote('"#{ $fa-var-face-meh }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-keyboard-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-keyboard-o:before {
	content: unquote('"#{ $fa-var-keyboard }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-flag-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-flag-o:before {
	content: unquote('"#{ $fa-var-flag }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mail-reply-all:before {
	content: unquote('"#{ $fa-var-reply-all }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-o:before {
	content: unquote('"#{ $fa-var-star-half-stroke }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-empty {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-empty:before {
	content: unquote('"#{ $fa-var-star-half-stroke }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-full {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-star-half-full:before {
	content: unquote('"#{ $fa-var-star-half-stroke }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-code-fork:before {
	content: unquote('"#{ $fa-var-code-branch }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-chain-broken:before {
	content: unquote('"#{ $fa-var-link-slash }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-unlink:before {
	content: unquote('"#{ $fa-var-link-slash }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-o:before {
	content: unquote('"#{ $fa-var-calendar }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-maxcdn {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-html5 {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-css3 {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-unlock-alt:before {
	content: unquote('"#{ $fa-var-unlock }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-minus-square-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-minus-square-o:before {
	content: unquote('"#{ $fa-var-square-minus }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-level-up:before {
	content: unquote('"#{ $fa-var-turn-up }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-level-down:before {
	content: unquote('"#{ $fa-var-turn-down }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pencil-square:before {
	content: unquote('"#{ $fa-var-square-pen }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-external-link-square:before {
	content: unquote('"#{ $fa-var-square-up-right }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-compass {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-down {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-down:before {
	content: unquote('"#{ $fa-var-square-caret-down }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-down {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-down:before {
	content: unquote('"#{ $fa-var-square-caret-down }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-up {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-up:before {
	content: unquote('"#{ $fa-var-square-caret-up }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-up {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-up:before {
	content: unquote('"#{ $fa-var-square-caret-up }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-right {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-right:before {
	content: unquote('"#{ $fa-var-square-caret-right }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-right {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-right:before {
	content: unquote('"#{ $fa-var-square-caret-right }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-eur:before {
	content: unquote('"#{ $fa-var-euro-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-euro:before {
	content: unquote('"#{ $fa-var-euro-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gbp:before {
	content: unquote('"#{ $fa-var-sterling-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-usd:before {
	content: unquote('"#{ $fa-var-dollar-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dollar:before {
	content: unquote('"#{ $fa-var-dollar-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-inr:before {
	content: unquote('"#{ $fa-var-indian-rupee-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rupee:before {
	content: unquote('"#{ $fa-var-indian-rupee-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-jpy:before {
	content: unquote('"#{ $fa-var-yen-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cny:before {
	content: unquote('"#{ $fa-var-yen-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rmb:before {
	content: unquote('"#{ $fa-var-yen-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yen:before {
	content: unquote('"#{ $fa-var-yen-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rub:before {
	content: unquote('"#{ $fa-var-ruble-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ruble:before {
	content: unquote('"#{ $fa-var-ruble-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rouble:before {
	content: unquote('"#{ $fa-var-ruble-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-krw:before {
	content: unquote('"#{ $fa-var-won-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-won:before {
	content: unquote('"#{ $fa-var-won-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-btc {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bitcoin {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bitcoin:before {
	content: unquote('"#{ $fa-var-btc }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-text:before {
	content: unquote('"#{ $fa-var-file-lines }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-alpha-asc:before {
	content: unquote('"#{ $fa-var-arrow-down-a-z }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-alpha-desc:before {
	content: unquote('"#{ $fa-var-arrow-down-z-a }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-amount-asc:before {
	content: unquote('"#{ $fa-var-arrow-down-short-wide }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-amount-desc:before {
	content: unquote('"#{ $fa-var-arrow-down-wide-short }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-numeric-asc:before {
	content: unquote('"#{ $fa-var-arrow-down-1-9 }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sort-numeric-desc:before {
	content: unquote('"#{ $fa-var-arrow-down-9-1 }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-youtube-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-youtube {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-xing {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-xing-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-youtube-play {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-youtube-play:before {
	content: unquote('"#{ $fa-var-youtube }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dropbox {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-stack-overflow {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-instagram {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-flickr {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-adn {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bitbucket {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bitbucket-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bitbucket-square:before {
	content: unquote('"#{ $fa-var-bitbucket }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tumblr {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tumblr-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-long-arrow-down:before {
	content: unquote('"#{ $fa-var-down-long }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-long-arrow-up:before {
	content: unquote('"#{ $fa-var-up-long }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-long-arrow-left:before {
	content: unquote('"#{ $fa-var-left-long }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-long-arrow-right:before {
	content: unquote('"#{ $fa-var-right-long }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-apple {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-windows {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-android {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-linux {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dribbble {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-skype {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-foursquare {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-trello {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gratipay {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gittip {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-gittip:before {
	content: unquote('"#{ $fa-var-gratipay }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sun-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-sun-o:before {
	content: unquote('"#{ $fa-var-sun }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-moon-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-moon-o:before {
	content: unquote('"#{ $fa-var-moon }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vk {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-weibo {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-renren {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pagelines {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-stack-exchange {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-right {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-right:before {
	content: unquote('"#{ $fa-var-circle-right }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-left {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-arrow-circle-o-left:before {
	content: unquote('"#{ $fa-var-circle-left }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-left {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-caret-square-o-left:before {
	content: unquote('"#{ $fa-var-square-caret-left }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-left {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-toggle-left:before {
	content: unquote('"#{ $fa-var-square-caret-left }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dot-circle-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-dot-circle-o:before {
	content: unquote('"#{ $fa-var-circle-dot }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vimeo-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-try:before {
	content: unquote('"#{ $fa-var-turkish-lira-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-turkish-lira:before {
	content: unquote('"#{ $fa-var-turkish-lira-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-plus-square-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-plus-square-o:before {
	content: unquote('"#{ $fa-var-square-plus }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-slack {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wordpress {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-openid {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-institution:before {
	content: unquote('"#{ $fa-var-building-columns }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bank:before {
	content: unquote('"#{ $fa-var-building-columns }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mortar-board:before {
	content: unquote('"#{ $fa-var-graduation-cap }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yahoo {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-reddit {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-reddit-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-stumbleupon-circle {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-stumbleupon {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-delicious {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-digg {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pied-piper-pp {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pied-piper-alt {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-drupal {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-joomla {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-behance {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-behance-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-steam {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-steam-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-automobile:before {
	content: unquote('"#{ $fa-var-car }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cab:before {
	content: unquote('"#{ $fa-var-taxi }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-spotify {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-deviantart {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-soundcloud {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-pdf-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-pdf-o:before {
	content: unquote('"#{ $fa-var-file-pdf }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-word-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-word-o:before {
	content: unquote('"#{ $fa-var-file-word }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-excel-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-excel-o:before {
	content: unquote('"#{ $fa-var-file-excel }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-powerpoint-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-powerpoint-o:before {
	content: unquote('"#{ $fa-var-file-powerpoint }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-image-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-image-o:before {
	content: unquote('"#{ $fa-var-file-image }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-photo-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-photo-o:before {
	content: unquote('"#{ $fa-var-file-image }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-picture-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-picture-o:before {
	content: unquote('"#{ $fa-var-file-image }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-archive-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-archive-o:before {
	content: unquote('"#{ $fa-var-file-zipper }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-zip-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-zip-o:before {
	content: unquote('"#{ $fa-var-file-zipper }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-audio-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-audio-o:before {
	content: unquote('"#{ $fa-var-file-audio }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-sound-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-sound-o:before {
	content: unquote('"#{ $fa-var-file-audio }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-video-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-video-o:before {
	content: unquote('"#{ $fa-var-file-video }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-movie-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-movie-o:before {
	content: unquote('"#{ $fa-var-file-video }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-file-code-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-file-code-o:before {
	content: unquote('"#{ $fa-var-file-code }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vine {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-codepen {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-jsfiddle {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-life-bouy:before {
	content: unquote('"#{ $fa-var-life-ring }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-life-buoy:before {
	content: unquote('"#{ $fa-var-life-ring }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-life-saver:before {
	content: unquote('"#{ $fa-var-life-ring }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-support:before {
	content: unquote('"#{ $fa-var-life-ring }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-circle-o-notch:before {
	content: unquote('"#{ $fa-var-circle-notch }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-rebel {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ra {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-ra:before {
	content: unquote('"#{ $fa-var-rebel }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-resistance {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-resistance:before {
	content: unquote('"#{ $fa-var-rebel }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-empire {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ge {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-ge:before {
	content: unquote('"#{ $fa-var-empire }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-git-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-git {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hacker-news {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-y-combinator-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-y-combinator-square:before {
	content: unquote('"#{ $fa-var-hacker-news }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yc-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-yc-square:before {
	content: unquote('"#{ $fa-var-hacker-news }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-tencent-weibo {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-qq {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-weixin {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wechat {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-wechat:before {
	content: unquote('"#{ $fa-var-weixin }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-send:before {
	content: unquote('"#{ $fa-var-paper-plane }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-paper-plane-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-paper-plane-o:before {
	content: unquote('"#{ $fa-var-paper-plane }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-send-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-send-o:before {
	content: unquote('"#{ $fa-var-paper-plane }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-circle-thin {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-circle-thin:before {
	content: unquote('"#{ $fa-var-circle }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-header:before {
	content: unquote('"#{ $fa-var-heading }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-futbol-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-futbol-o:before {
	content: unquote('"#{ $fa-var-futbol }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-soccer-ball-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-soccer-ball-o:before {
	content: unquote('"#{ $fa-var-futbol }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-slideshare {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-twitch {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yelp {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-newspaper-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-newspaper-o:before {
	content: unquote('"#{ $fa-var-newspaper }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-paypal {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google-wallet {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-visa {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-mastercard {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-discover {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-amex {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-paypal {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-stripe {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bell-slash-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-bell-slash-o:before {
	content: unquote('"#{ $fa-var-bell-slash }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-trash:before {
	content: unquote('"#{ $fa-var-trash-can }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-copyright {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-eyedropper:before {
	content: unquote('"#{ $fa-var-eye-dropper }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-area-chart:before {
	content: unquote('"#{ $fa-var-chart-area }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pie-chart:before {
	content: unquote('"#{ $fa-var-chart-pie }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-line-chart:before {
	content: unquote('"#{ $fa-var-chart-line }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-lastfm {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-lastfm-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ioxhost {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-angellist {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-cc:before {
	content: unquote('"#{ $fa-var-closed-captioning }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ils:before {
	content: unquote('"#{ $fa-var-shekel-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-shekel:before {
	content: unquote('"#{ $fa-var-shekel-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sheqel:before {
	content: unquote('"#{ $fa-var-shekel-sign }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-buysellads {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-connectdevelop {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-dashcube {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-forumbee {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-leanpub {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sellsy {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-shirtsinbulk {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-simplybuilt {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-skyatlas {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-diamond {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-diamond:before {
	content: unquote('"#{ $fa-var-gem }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-transgender:before {
	content: unquote('"#{ $fa-var-mars-and-venus }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-intersex:before {
	content: unquote('"#{ $fa-var-mars-and-venus }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-transgender-alt:before {
	content: unquote('"#{ $fa-var-transgender }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook-official {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-facebook-official:before {
	content: unquote('"#{ $fa-var-facebook }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pinterest-p {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-whatsapp {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hotel:before {
	content: unquote('"#{ $fa-var-bed }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-viacoin {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-medium {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-y-combinator {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yc {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-yc:before {
	content: unquote('"#{ $fa-var-y-combinator }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-optin-monster {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-opencart {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-expeditedssl {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery-4:before {
	content: unquote('"#{ $fa-var-battery-full }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery:before {
	content: unquote('"#{ $fa-var-battery-full }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery-3:before {
	content: unquote('"#{ $fa-var-battery-three-quarters }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery-2:before {
	content: unquote('"#{ $fa-var-battery-half }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery-1:before {
	content: unquote('"#{ $fa-var-battery-quarter }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-battery-0:before {
	content: unquote('"#{ $fa-var-battery-empty }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-object-group {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-object-ungroup {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-sticky-note-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-sticky-note-o:before {
	content: unquote('"#{ $fa-var-note-sticky }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-jcb {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-cc-diners-club {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-clone {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hourglass-o:before {
	content: unquote('"#{ $fa-var-hourglass-empty }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hourglass-1:before {
	content: unquote('"#{ $fa-var-hourglass-start }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hourglass-half:before {
	content: unquote('"#{ $fa-var-hourglass }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hourglass-2:before {
	content: unquote('"#{ $fa-var-hourglass }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hourglass-3:before {
	content: unquote('"#{ $fa-var-hourglass-end }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-rock-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-rock-o:before {
	content: unquote('"#{ $fa-var-hand-back-fist }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-grab-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-grab-o:before {
	content: unquote('"#{ $fa-var-hand-back-fist }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-paper-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-paper-o:before {
	content: unquote('"#{ $fa-var-hand }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-stop-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-stop-o:before {
	content: unquote('"#{ $fa-var-hand }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-scissors-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-scissors-o:before {
	content: unquote('"#{ $fa-var-hand-scissors }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-lizard-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-lizard-o:before {
	content: unquote('"#{ $fa-var-hand-lizard }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-spock-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-spock-o:before {
	content: unquote('"#{ $fa-var-hand-spock }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-pointer-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-pointer-o:before {
	content: unquote('"#{ $fa-var-hand-pointer }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-peace-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-hand-peace-o:before {
	content: unquote('"#{ $fa-var-hand-peace }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-registered {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-creative-commons {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gg {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gg-circle {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-odnoklassniki {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-odnoklassniki-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-get-pocket {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wikipedia-w {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-safari {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-chrome {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-firefox {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-opera {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-internet-explorer {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-television:before {
	content: unquote('"#{ $fa-var-tv }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-contao {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-500px {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-amazon {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-plus-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-plus-o:before {
	content: unquote('"#{ $fa-var-calendar-plus }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-minus-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-minus-o:before {
	content: unquote('"#{ $fa-var-calendar-minus }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-times-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-times-o:before {
	content: unquote('"#{ $fa-var-calendar-xmark }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-check-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-calendar-check-o:before {
	content: unquote('"#{ $fa-var-calendar-check }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-map-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-map-o:before {
	content: unquote('"#{ $fa-var-map }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-commenting:before {
	content: unquote('"#{ $fa-var-comment-dots }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-commenting-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-commenting-o:before {
	content: unquote('"#{ $fa-var-comment-dots }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-houzz {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vimeo {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-vimeo:before {
	content: unquote('"#{ $fa-var-vimeo-v }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-black-tie {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-fonticons {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-reddit-alien {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-edge {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-credit-card-alt:before {
	content: unquote('"#{ $fa-var-credit-card }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-codiepie {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-modx {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-fort-awesome {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-usb {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-product-hunt {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-mixcloud {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-scribd {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pause-circle-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-pause-circle-o:before {
	content: unquote('"#{ $fa-var-circle-pause }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-stop-circle-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-stop-circle-o:before {
	content: unquote('"#{ $fa-var-circle-stop }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bluetooth {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bluetooth-b {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-gitlab {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wpbeginner {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wpforms {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-envira {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wheelchair-alt {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-wheelchair-alt:before {
	content: unquote('"#{ $fa-var-accessible-icon }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-question-circle-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-question-circle-o:before {
	content: unquote('"#{ $fa-var-circle-question }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-volume-control-phone:before {
	content: unquote('"#{ $fa-var-phone-volume }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-asl-interpreting:before {
	content: unquote('"#{ $fa-var-hands-asl-interpreting }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-deafness:before {
	content: unquote('"#{ $fa-var-ear-deaf }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-hard-of-hearing:before {
	content: unquote('"#{ $fa-var-ear-deaf }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-glide {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-glide-g {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-signing:before {
	content: unquote('"#{ $fa-var-hands }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-viadeo {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-viadeo-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-snapchat {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-snapchat-ghost {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-snapchat-ghost:before {
	content: unquote('"#{ $fa-var-snapchat }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-snapchat-square {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-pied-piper {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-first-order {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-yoast {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-themeisle {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus-official {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus-official:before {
	content: unquote('"#{ $fa-var-google-plus }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus-circle {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-google-plus-circle:before {
	content: unquote('"#{ $fa-var-google-plus }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-font-awesome {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-fa {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-fa:before {
	content: unquote('"#{ $fa-var-font-awesome }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-handshake-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-handshake-o:before {
	content: unquote('"#{ $fa-var-handshake }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-envelope-open-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-envelope-open-o:before {
	content: unquote('"#{ $fa-var-envelope-open }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-linode {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-address-book-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-address-book-o:before {
	content: unquote('"#{ $fa-var-address-book }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vcard:before {
	content: unquote('"#{ $fa-var-address-card }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-address-card-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-address-card-o:before {
	content: unquote('"#{ $fa-var-address-card }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-vcard-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-vcard-o:before {
	content: unquote('"#{ $fa-var-address-card }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-user-circle-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-user-circle-o:before {
	content: unquote('"#{ $fa-var-circle-user }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-user-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-user-o:before {
	content: unquote('"#{ $fa-var-user }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-id-badge {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-drivers-license:before {
	content: unquote('"#{ $fa-var-id-card }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-id-card-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-id-card-o:before {
	content: unquote('"#{ $fa-var-id-card }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-drivers-license-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-drivers-license-o:before {
	content: unquote('"#{ $fa-var-id-card }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-quora {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-free-code-camp {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-telegram {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer-4:before {
	content: unquote('"#{ $fa-var-temperature-full }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer:before {
	content: unquote('"#{ $fa-var-temperature-full }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer-3:before {
	content: unquote('"#{ $fa-var-temperature-three-quarters }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer-2:before {
	content: unquote('"#{ $fa-var-temperature-half }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer-1:before {
	content: unquote('"#{ $fa-var-temperature-quarter }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-thermometer-0:before {
	content: unquote('"#{ $fa-var-temperature-empty }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bathtub:before {
	content: unquote('"#{ $fa-var-bath }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-s15:before {
	content: unquote('"#{ $fa-var-bath }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-window-maximize {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-window-restore {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-times-rectangle:before {
	content: unquote('"#{ $fa-var-rectangle-xmark }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-window-close-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-window-close-o:before {
	content: unquote('"#{ $fa-var-rectangle-xmark }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-times-rectangle-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-times-rectangle-o:before {
	content: unquote('"#{ $fa-var-rectangle-xmark }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-bandcamp {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-grav {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-etsy {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-imdb {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-ravelry {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-eercast {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-eercast:before {
	content: unquote('"#{ $fa-var-sellcast }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-snowflake-o {
	font-family: 'Font Awesome 6 Free', serif;
	font-weight: 400;
}
.#{$fa-css-prefix}.#{$fa-css-prefix}-snowflake-o:before {
	content: unquote('"#{ $fa-var-snowflake }"');
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-superpowers {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-wpexplorer {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}

.#{$fa-css-prefix}.#{$fa-css-prefix}-meetup {
	font-family: 'Font Awesome 6 Brands', sans-serif;
	font-weight: 400;
}
