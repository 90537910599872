.lang-dropdown .btn-secondary {
	margin: 0 !important;
	background-color: white !important;
	border-radius: 0 !important;
	border: 1px solid #cccccc !important;
	border-color: #cccccc !important;
	border-top-left-radius: 4px !important;
	border-bottom-left-radius: 4px !important;
	color: #000 !important;
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
	text-transform: lowercase;
}
