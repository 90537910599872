$success: #4caf50;
$error: #cc0000;
$editor-border-color: #e8e8e8;
$toolbar-background-color: #f1f1f1;
$toolbar-item-color: #808080;
$toolbar-item-background-color: transparent;
$toolbar-item-background-color-active: #d9d9d9;
$time-config-border-color: #ebeff2;
$time-config-background-color: #fafbff;
$tabs-background-color: #f1f5f9;
$tabs-background-color-selected: #039be5;
