@import '../../../../global/fonts.scss';
@import 'utils.scss';

#multisport-widget-wrapper {
   display: flex;
   flex-wrap: wrap;

   i.icon-end, i.icon-before-end {
        position: absolute;
        cursor: pointer;
   }

   .competition-node-img {
        width: 16px;
        max-height: 16px;
        margin-right: 8px;
    }

   // List of events wrapper
    #multisport-widget-events-list-wrapper {
       @include OpenSansFont();
       @include GeneralWrappers();
       width: 37%;
       color: $text-gray-color;
       height: 100%;

       & > span {
            @include OpenSansFont();
            color: #434343;
            margin-top: 8px !important;
            margin-bottom: 16px !important;
       }

       .custom-search-date-wrapper {
            margin-top: calculateIndent(2);
            margin-bottom: calculateIndent();
       }

        .multisport-widget-sport-section-events {
            // Sport header toggle
            .multisport-widget-sport-data-header {
                position: relative;
                display: flex;
                align-items: center;
                height: 40px;
                margin: calculateIndent(2) auto;
                background-color: $dark-gray-background-color;
                max-height: 450px;

                i, .sport-logo {
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    margin-left: calculateIndent(3);

                    &.icon-end {
                        right: calculateIndent(4);
                    }
                }

                span {
                    margin-left: 4px;
                    text-transform: capitalize;
                    color: $text-gray-color;
                    @include OpenSansFont(700);
                }

                .bubble-displayed-count {
                    right: calculateIndent(8);
                }
            }

            // Tournament header toggle
            .multisport-widget-tournament-data-header {
                position: relative;
                display: flex;
                align-items: center;
                height: 40px;
                background-color: $gray-background-color;
                margin: calculateIndent(2);

                > img.circle-plus {
                    right: calculateIndent(10);
                    position: absolute;
                }

                i, img {
                    margin-left: calculateIndent();

                    &.icon-before-end {
                        right: calculateIndent(10);
                    }

                    &.icon-end {
                        right: calculateIndent(2);
                    }
                }

                span {
                    @include OpenSansFont();
                    margin-left: 4px;
                    color: $text-gray-color
                }

                .bubble-displayed-count {
                    right: calculateIndent(6);
                }
            }

            .multisport-widget-tournament-data-wrapper {
                overflow: auto;
                max-height: 350px;
            }

            // Tournament games
            .multisport-widget-tournament-data {
                position: relative;
                display: flex;
                align-items: center;
                min-height: 38px;
                margin: calculateIndent() calculateIndent(2);
                border-radius: 8px;
                border: 1px solid #F0F0F0;

                .multisport-widget-events-list-game-date {
                    @include OpenSansFont(400, 16px, 11px);
                    color: #8C8C8C;
                    margin: 0 8px;
                }

                .multisport-widget-events-list-game-title {
                    @include OpenSansFont();
                    color: $text-gray-color;
                    width: calc(100% - 35px);
                }

                .multisport-widget-events-list-game-icons-wrapper {
                    margin-right: calculateIndent(1.5);
                    display: flex;
                    justify-content: space-between;

                    img {
                        margin-right: calculateIndent();

                        &.active, &[aria-disabled="true"] {
                            cursor: unset;
                        }
                    }
                }
            }
        }

        #multisport-widget-no-events-info-wrapper {
            @include OpenSansFont();
            margin: calculateIndent(2);
            display: inline-flex;
            padding: 4px 16px;
            align-items: center;
            background-color: #FFDC1A;
            border-radius: 6px;
            background: #FFFBE6;

            .info-logo {
                width: 24px;
                height: 16px;
                background-repeat: no-repeat;
                margin-right: calculateIndent();
            }
        }
    }

    #multisport-widget-right-section {
        width: 62%;
        margin-left: 1%;

        #multisport-widget-configure-date-wrapper {
            @include GeneralWrappers();
            background: linear-gradient(to bottom, #1058E1 50%, #0E4EC8 50%);
            color: white;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            gap: 16px;

            #search-config-date, .custom-search-date-input-left-arrow, .custom-search-date-input-right-arrow {
                background-color: white;
                color: #0F172A;
            }

            #search-config-date {
                min-width: 170px;
            }

          #competition-list-select {
            width: 250px;
            height: 40px
          }

        }

        .multisport-widget-events-preview-wrapper {
            @include GeneralWrappers();

            h3 {
                @include OpenSansFont(700, 28px, 20px);
            }

            .multisport-widget-preview-toggler-title-wrapper {
                color: #000000;
                @include OpenSansFont();
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;

                i {
                    right: calculateIndent(2);
                }

                .bubble-displayed-count {
                    right: calculateIndent(5);
                }
            }

            .top-events-info {
                @include OpenSansFont(700);
                padding: 8px;
                border-radius: 4px;
                background: #B8D0FE;
                margin-top: 16px;
            }

            .multisport-widget-preview-tournament-data-wrapper {
                display: flex;
                flex-basis: 90%;
                margin: 8px 3% 8px 10%;
                overflow: hidden;

                .multisport-widget-preview-tournament-header {
                    @include OpenSansFont();
                    display: flex;
                    width: 100%;
                    height: 40px;
                    padding: 8px;
                    justify-content: space-between;
                    border-radius: 4px;
                    background: #F0F0F0;
                    align-items: center;
                }

                .rst__tree{
                    .remove-multisport-item-icon {
                        cursor: pointer;
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        right: -15px;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        background-repeat: no-repeat;
                        background-size: auto;
                        background-position: center;
                    }

                    .rst__nodeContent {
                        min-width: 75%;

                        .rst__row{
                            flex-direction: row-reverse;
                            border-radius:  4px;
                            background:  #F0F0F0;

                            .rst__moveHandle {
                                background-color: #595959;
                                border: none;
                                background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00Ljc1IDUuMjc3MzRDNC4zMzU3OSA1LjI3NzM0IDQgNS42MTMxMyA0IDYuMDI3MzVDNCA2LjQ0MTU2IDQuMzM1NzkgNi43NzczNSA0Ljc1IDYuNzc3MzVMMTkuMzc4NiA2Ljc3NzM1QzE5Ljc5MjggNi43NzczNSAyMC4xMjg2IDYuNDQxNTYgMjAuMTI4NiA2LjAyNzM1QzIwLjEyODYgNS42MTMxMyAxOS43OTI4IDUuMjc3MzUgMTkuMzc4NiA1LjI3NzM1TDQuNzUgNS4yNzczNFpNMTkuMzc4NiAxM0w0Ljc1IDEzQzQuMzM1NzkgMTMgNCAxMi42NjQyIDQgMTIuMjVDNCAxMS44MzU4IDQuMzM1NzkgMTEuNSA0Ljc1IDExLjVMMTkuMzc4NiAxMS41QzE5Ljc5MjggMTEuNSAyMC4xMjg2IDExLjgzNTggMjAuMTI4NiAxMi4yNUMyMC4xMjg2IDEyLjY2NDIgMTkuNzkyOCAxMyAxOS4zNzg2IDEzWk00IDE4LjQ3MThDNCAxOC4wNTc2IDQuMzM1NzkgMTcuNzIxOCA0Ljc1IDE3LjcyMThMMTkuMzc4NiAxNy43MjE4QzE5Ljc5MjggMTcuNzIxOCAyMC4xMjg2IDE4LjA1NzYgMjAuMTI4NiAxOC40NzE4QzIwLjEyODYgMTguODg2IDE5Ljc5MjggMTkuMjIxOCAxOS4zNzg2IDE5LjIyMThMNC43NSAxOS4yMjE4QzQuMzM1NzkgMTkuMjIxOCA0IDE4Ljg4NiA0IDE4LjQ3MThaIiBmaWxsPSIjNTk1OTU5Ii8+Cjwvc3ZnPgo=') no-repeat center;
                                -webkit-box-shadow: none;
                                box-shadow: none;
                            }

                            .preview-event-row-data {
                                display: inline-flex;

                                .title-container {
                                    display: flex;
                                    flex-direction: column;
                                    width: 100%;
                                    justify-content: center;

                                    .participant-wrapper {
                                        display: inline-flex;
                                        align-items: center;

                                        .participant-name {
                                            @include OpenSansFont();
                                            color: #121212;
                                        }

                                        .participant-img {
                                            width: 16px;
                                            height: 16px;
                                            margin-right: calculateIndent(1/2);
                                        }
                                    }
                                }

                                .date-time-wrapper {
                                    display: flex;
                                    flex-direction: column;
                                    margin: 0 calculateIndent(2) 0 calculateIndent();
                                    align-items: center;
                                    justify-content: center;
                                    color: #8C8C8C;

                                    .time {
                                        @include OpenSansFont(400, 18px, 11px);
                                    }

                                    .date  {
                                        text-transform: uppercase;
                                        @include OpenSansFont(700, 19px, 11px);
                                    }
                                }
                            }

                            .rst__rowContents {
                                border: none;
                                background:  #F0F0F0;
                                -webkit-box-shadow: none;
                                box-shadow: none;

                                .rst__rowTitle {
                                    @include OpenSansFont()

                                };
                            }
                        }
                    }
                }
            }

        }
    }
}

#multisport-widget-footer-actions-wrapper{
    display: flex;
    justify-content: flex-end;
    display: flex;
    width: 100%;
    padding: 16px 24px;
    justify-content: flex-end;
    align-items: center;
    background: #FFF;
    box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
    position: fixed;
    right: 50px;
    bottom: 0;
    width: 100%;
}


@media only screen and (max-width: 900px){
    #multisport-widget-wrapper {
        #multisport-widget-events-list-wrapper, #multisport-widget-right-section {
            margin-left: 0;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1024px){
    #multisport-widget-footer-actions-wrapper {
        right: 0;
    }
}
