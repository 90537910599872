#profile-wrapper {
	.nav-tabs {
		.nav-item {
			.nav-link {
				&.active {
					border-bottom-color: #e4e5e6;
					font-weight: bold;
				}
			}
		}
	}
}
