.image-upload-info {
	border: 1px solid #cccccc;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	padding: 0 10px;
	height: 35px;
	text-overflow: ellipsis;
}

.assets-image-container {
	flex: 1;
	max-width: 90px;
	min-width: 50px;
	overflow: hidden;
	max-height: 90px;
	height: 90px;
	background: #ecf0f3;
}

.image-loader {
	flex: 1;
	max-width: 90px;
	min-width: 50px;
	min-height: 90px !important;
}

.image-input-container {
	flex: 2;

	.delete-btn {
		max-height: 35px;
	}
}

.asset-image {
	max-width: none !important;
}

.asset-expand-item {
	max-height: 90px;
}

.asset-squad-image {
	height: 100%;
	width: auto !important;
	max-width: none;
}

.team-color-picker-label {
	margin-bottom: 0.5rem;
	margin-right: 5px;
}

.team-label {
	margin-bottom: 0.5rem;
	font-weight: bold;
}

@media screen and (min-width: 996px) and (max-width: 1650px) {
	.assets-image-container {
		margin-bottom: 10px;
	}

	.image-upload-info {
		margin-left: 0px;
	}

	.image-upload-container {
		flex-direction: column;
	}
}

@media screen and (max-width: 500px) {
	.assets-image-container {
		margin-bottom: 10px;
	}

	.image-upload-info {
		margin-left: 0px;
	}

	.image-upload-container {
		flex-direction: column;
	}
}
