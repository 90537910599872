.custom-search-input-wrapper {
    position: relative;
      
    .custom-search-input {
        text-indent: 32px;
        width: 100%;
        border-radius: 6px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        height: 40px;
    }
    
    .custom-search-input-icon {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        color: #D9D9D9
    }
}