@keyframes border-pulsate-interrupted-right {
	0% {
		border-right-color: rgba(238, 21, 21, 1);
	}
	50% {
		border-right-color: rgba(238, 21, 21, 0);
	}
	100% {
		border-right-color: rgba(238, 21, 21, 1);
	}
}

.border-pulsate-interrupted-right {
	border-right: 5px solid;
	animation: border-pulsate-interrupted-right 2s infinite;
}
