.image-upload-container-v2 {
	.image-preview-container {
		height: 100%;
		img {
			height: 100%;
			width: 100%;
			max-height: 90px;
			max-width: 90px;
			min-height: 60px;
			min-width: 60px;
		}
	}

	.input-group {
		button {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.image-crop-container {
		margin: 0 !important;
		button {
			width: 100%;
		}
	}
}
