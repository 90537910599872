.collaborators-list {
  margin-bottom: 18px;
  background-color: #F1F3F5;

  .author-image {
    border-radius: 50%;
    max-width: 25px;
    max-height: 25px;
    margin-right: 10px;
  }
}

.events-list {
  margin-bottom: 18px;
  margin-left: 10%;
  background-color: #F1F3F5;

  .team-image {
    border-radius: 50%;
    max-width: 25px;
    max-height: 25px;
    margin-right: 10px;
  }
}

.sport-entity-pill {
  display: inline-block;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  overflow: hidden;
  padding-left: 5px;
  cursor: pointer;

  .sport-entity-pill-name {
    display: inline-block;
  }

  .sport-entity-pill-remove {
    width: 20px;
    height: 100%;
    display: inline-block;
    background-color: #e3e3e3;
    color: #575b5e;
    text-align: center;
  }
}

.team-border {
  border-left: 2px solid #fc1111;
  border-bottom: 2px solid #fc1111;
}

