.widgets-embed-container {
	.add-block {
		display: none;
	}
	.title-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.cursor-draggable {
		display: none;
	}
	.widget-loader-code-container {
		background-color: #f1f1f1;
		padding: 10px;
		.widget-loader-code {
			white-space: pre-wrap;
			font-family: Consolas, 'courier new', sans-serif;
			color: crimson;
		}
	}
	.block {
		h6 {
			input[type='checkbox'] {
				display: none !important;
			}
		}
	}
}

.widget-list-container {
	min-width: 150px;

	.card {
		min-width: 200px;

		.card-footer {
			background-color: #ffffff;
			border-top: none;
		}
	}
}
