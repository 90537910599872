@mixin desktop {
	@media (max-width: 1200px) {
		@content;
	}
}

@mixin laptops {
	@media (max-width: 1024px) {
		@content;
	}
}

@mixin tablet {
	@media (max-width: 768px) {
		@content;
	}
}

@mixin phone {
	@media (max-width: 480px) {
		@content;
	}
}
