@import '../../../../scss/custom/media_query';

.content-list-header {
	.btn-create-new-wrapper {
		.btn-create-new {
			width: 40%;
			max-width: 125px;
		}
	}
}

table.content-list-table {
	display: block;
	overflow-x: auto;

	.w-title {
		a {
			color: black;
		}
	}

	tbody,
	thead {
		display: table;
		width: 100%;
	}

	.dropdown-row-btn {
		width: 90%;
		margin: 5px auto;
	}
}

@include tablet {
	.content-list-header {
		.btn-create-new-wrapper {
			padding-right: 15px;

			.btn-create-new {
				max-width: unset;
				width: 100%;
				margin-bottom: 10px;
			}
		}
	}
}
