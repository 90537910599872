.notifications-pill {
  display: flex;
  padding: 3px 6px 2px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 44px;
  background: #7DB043;

  .notifications-label {
    color: #252831;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 8px;
  }
}
