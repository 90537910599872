/**
 * Loading classes
 */

.watermark-tooltip {
	width: 3%;
	margin: -30px 0px 0px -21px;
}

.loading-overlay {
	position: relative;
	z-index: 1;
	min-height: 200px;

	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.9);
	}

	&::after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		margin-top: -30px;
		margin-left: -30px;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		border: 5px solid grey;
		border-top-color: #1e7bff;
		animation: customLoading 2s linear infinite;
	}
}
