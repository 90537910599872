@import "../general/export.scss";

$padding: 6px;

.custom-phone-input-wrapper {
    .custom-phone-inputs-holder {
        display: flex;

        #country-select-prefix {
            display: flex;
            align-items: center;
            margin-right: 4px;

            > div {
                border-radius: 6px;
                width: 100px;

                :last-child {
                    svg {
                        color: #666666;
                    }

                    span[class*=indicatorSeparator] {
                        display: none;
                    }

                    div[class*=indicatorContainer] {
                        padding: 0 4px 0 0;
                    }
                }
            }

            div[class*=menu] {
                width: 300px;
            }

            .custom-select-option {
                display: flex;

                &.selected-option {
                    justify-content: center;
                    align-items: center;
                }
            }

            .image-option-preview, .image-preview {
                width: 24px;
                height: 16px;
                margin-right: 8px;
                background-position: center;
                background-repeat: no-repeat;
            }

            .image-preview {
                margin-right: 4px;
            }

            .preview-option-text {
                color: #BFBFBF;
                @include OpenSansFont(400, 20px, 14px);
            }
        }      
    }   
}

