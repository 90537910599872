.w-80 {
	width: 80%;
}

.w-title {
	width: 45%;
}

.w-date,
.w-buttons,
 {
	width: 15%;
}



.w-origins,{
  width: 10%;
  text-align: center;
}

.w-category,
.w-createdBy, {
	width: 10%;
}

.display-none {
	@media screen and (max-width: 770px) {
		display: none !important;
	}
}
