.blocky {
	.dropdown-toggle {
		width: auto;
	}

	.add-block {
		.dropdown-menu {
			.fa {
				min-width: 20px;
				text-align: center;
			}
		}

		.dropdown-toggle:after {
			content: none;
		}
	}

	.block {
		position: relative;
		width: 100%;
		min-height: 50px;
		padding-bottom: 15px;
		background-position: bottom 20px right 20px !important;
		background-size: 120px !important;
		background-repeat: no-repeat;

		.btn {
			height: calc(1.5em + 0.75rem + 5px);
		}

		.block-movement {
			position: absolute;
			z-index: 5;
			top: -1px;
			left: -1px;
			margin: 0;
			padding: 0;

			.move-up,
			.move-down {
				color: #999999;
				&:hover {
					color: $dark;
				}
			}
		}

		.block-actions {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			padding: 0;
			z-index: 5;
			cursor: pointer;
		}

		&:hover {
			.block-movement {
				display: block !important;
			}
			.block-actions {
				display: block !important;
			}
		}

		.no-preview-image {
			max-width: 300px;
		}

		.block-content {
			position: relative;
			margin: 0 auto;
			padding-left: 15px;
			padding-right: 15px;

			p {
				font-size: 16px;
				line-height: 1.5;
				margin: 0;
			}

			.content-image {
				max-width: 250px;
				max-height: 250px;
			}

			.gallery-title,
			.image-title {
				padding-left: 20px;
				overflow: hidden;
			}

			@media screen and (max-width: 600px) {
				.gallery-title,
				.image-title {
					padding-left: 0;
				}

				.content-container {
					flex-direction: column;
				}
			}

			.gallery-number-images {
				z-index: 5;
				background: $white;
				right: 10px;
				bottom: 15px;
				padding: 5px 10px;
			}
		}
	}
}
