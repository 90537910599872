
.limit-exceeded {
  color: #f44336;
}

.summary {
  border-width: 2px;
}

.custom-label {
  color: #0F172A;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}


