.blocky-dropzone-custom-class {
	background: transparent;
	border: none;
}

.image-processing-container {
	position: relative;

	.image-uploading-loader-container {
		width: 100%;
		height: 100%;
		z-index: 9999;
		background: rgba(255, 255, 255, 0.9);
		position: absolute;

		.row-loader {
			position: absolute;
			top: 30%;
			width: 100%;
		}

		.row-loader-text {
			position: absolute;
			top: 45%;
			width: 100%;
		}
	}

	.hide-image-loader {
		display: none;
	}
}
