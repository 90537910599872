$tournamentTeamLogoSize: 32px;
$tournamentTeamLogoMarginR: 10px;

.tournaments-list-wrapper {
	.list-group-item-action {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;

		button {
			min-width: 120px;
		}
	}

	span {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		flex: 1;
		padding: 3px 0 0 0;
	}
}

.tournaments-modal-wrapper {
	.tournaments-modal-inner-wrapper {
		display: flex;
		align-items: center;
		margin: 0 0 5px;

		.tournament-team-logo {
			width: $tournamentTeamLogoSize;
			min-width: $tournamentTeamLogoSize;
			max-width: $tournamentTeamLogoSize;
			height: $tournamentTeamLogoSize;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			margin-right: $tournamentTeamLogoMarginR;
		}

		&:last-child {
			justify-content: space-between;
			margin: 20px 0 10px;

			.alert,
			button {
				min-height: 47px;
			}
		}

		.tournaments-modal-select {
			flex: 0 0 auto;
			margin: 0 5px 0 0;
			width: calc(80% - 5px - #{$tournamentTeamLogoMarginR} - #{$tournamentTeamLogoSize}); // margin + image width
		}

		button {
			flex: 0 0 auto;
			height: 38px;
			width: 20%;
		}
	}

	.tournaments-modal-dropdown {
		margin: 0 0 30px;
	}

	.text-danger {
		display: none;
	}

	label {
		display: none;
	}
}

.modal-footer-modified {
	justify-content: space-between;
}

@media screen and (max-width: 556px) {
	.tournaments-list-wrapper {
		.list-group-item-action {
			button {
				min-width: unset;
			}
		}
	}

	.tournaments-modal-wrapper {
		.tournaments-modal-inner-wrapper {
			.tournaments-modal-select {
				width: calc(75% - 5px - #{$tournamentTeamLogoMarginR} - #{$tournamentTeamLogoSize});
			}

			button {
				width: 25%;
			}
		}
	}
}
