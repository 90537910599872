.toggle-audit-log-btn {
	width: 55px;
	text-align: center;
	font-size: 15px;
	font-weight: bold;
	color: #73818f;
	cursor: pointer;
	float: right;
	display: flex;
	margin: auto;
}

.toggle-audit-log-icon {
	margin: auto;
}

.audit-log-container {
	background-color: #fff;
	width: 100%;
	z-index: 1019;
	height: auto;
	overflow-y: auto;
	transition: all 0.25s ease-in-out;
}

.audit-log-search-button {
	margin-top: 30px;
	float: right;
	@media (max-width: 556px) {
		float: none;
		flex-direction: row;
		margin-top: 0;
		margin-bottom: 5px;
		display: flex;
	}
}

.audit-log-filter-row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
	@media (max-width: 556px) {
		flex-direction: row;
		display: contents;
		height: auto;
	}
}

.audit-entity-select {
	width: 100%;
	display: flex;
	flex-flow: column;
}

.audit-log-badge {
	padding: 5px;
	font-weight: bold;
	text-transform: uppercase;
	border-radius: 20px;
	width: 93px;
	@media (max-width: 556px) {
		text-transform: none;
		margin-left: 10px;
		border-radius: 20px;
		width: 10px;
	}
}

.audit-log-page {
	background-color: #fff;
	width: 100%;
	overflow-y: auto;
	transition: all 0.25s ease-in-out;
}
