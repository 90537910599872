@import "../general/export.scss";

.custom-input-wrapper {
    min-width: 150px;
    width: 100%;
    display: inline-block;
    font-family: Arial, sans-serif;
    position: relative;

    &[aria-disabled="true"] {
      .custom-input {
        background-color: #F5F5F5;
        cursor: not-allowed;
      }
    }
  
  label {
    display: block;
    font-size: 14px;
    color: #000;
    margin-bottom: $elementsMargin;
  }

  .custom-input {
    text-indent: 12px;
    width: 100%;
    border-radius: 6px;
    border: 1px solid hsl(0,0%,80%);
    background: #FFF;
    line-height: $lineHeight;
    height: $lineHeight;
  }
}  