.color {
	width: 36px;
	height: 14px;
	border: 1px solid lightgray;
	border-radius: 2px;
}

.swatch {
	padding: 5px;
	background: #fff;
	border-radius: 1px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	display: inline-block;
	cursor: pointer;
}

.popover {
	position: absolute;
	z-index: 2;
	top: 62px;

	.github-picker {
		border: none !important;
	}
}

.cover {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.team-color-picker-label {
	display: block;
}

.no-color-selected-bg {
	background-image: linear-gradient(45deg, #ffffff 25%, #808080 25%, #808080 50%, #ffffff 50%, #ffffff 75%, #808080 75%, #808080 100%);
	background-size: 10px 10px;
}

.popover-home {
	left: -54px;
}

.popover-away {
	left: -154px;
}
