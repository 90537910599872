@media (max-width: 800px) {
  .gallery-block-masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }
}

.gallery-block-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  //margin-left: 40px; /* gutter size offset */
  margin-top: 20px; /* gutter size offset */
  width: auto;
  height: auto;
}
.gallery-block-masonry-grid_column {
  margin-left: 5px;
  padding-left: 5px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.gallery-block-masonry-grid_column > img {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 50px;
}
