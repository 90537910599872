.row-style {
	&:nth-child(2n) {
		background-color: #f0f3f5 !important;
	}
}

.link-container {
	height: 100% !important;
}

.countries-select-container {
	.countries-select {
		width: 95%;
	}
}

.tournament-list-name-col {
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;

	&:hover {
		text-decoration: underline;
	}

	.tournament-list-logo {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		height: 45px;
		width: 45px;
		margin-right: 10px;
	}
}

@media screen and (max-width: 768px) {
	.countries-select-container {
		.countries-select {
			width: 100%;
		}
	}
}

@media screen and (max-width: 480px) {
	.tournament-list-name-col {
		display: flex;
		justify-content: center;
		text-align: center;

		.tournament-list-logo {
			margin-right: unset;
		}
	}
}
