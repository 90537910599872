@import '../../../../global/fonts.scss';

.designed-button {
    display: inline-flex;
    height: 38px;
    padding: 4px 16px;
    align-items: center;
    cursor: pointer;
    @include OpenSansFont(700);

    &[aria-disabled="true"] {
          background: #0790E1 !important;
          cursor: default;
      }


    &.rounded {
        border-radius: 6px;
    }

    &.gray-border {
        border: 1px solid #D4D4D4; 
    }

    &.blue {
        background: #2A72FB;
        color: white;
    }

    &.red {
        background: #D92033;
        color: white;
    }
}