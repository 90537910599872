   @import '../general/export.scss';
   
   $gap: 16px;

.react-dropzone-wrapper {
    margin-bottom: 16px;

   .react-dropzone-custom-class {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-radius: 6px;
        border: 1px dashed  #D9D9D9;
        background: #FFF; 
        padding: 24px 12px;
        margin-bottom: 16px;

        span {
            margin-bottom: unset;
            color: #0F172A;
            @include OpenSansFont(600, 20px, 14px);
        }

        .dropzone-icon-upload {
            width: 24px;
            height: 24px;
            background-image: url('../../../../assets/icons/general/upload_file.svg');
            margin-bottom: 4px;
        }

        .dropzone-dnd-text-wrapper {
            display: inline-flex;

            .dropzone-dnd-text {
                display: inline-block;
                @include OpenSansFont();

                &.link-style {
                    text-decoration: underline;
                    color: #1058E1;
                    cursor: pointer;
                }
            }
        }
        

        .dropzone-dnd-subtext {
            display: block;
            width: 100%;
            text-align: center;
            color:  #8C8C8C;
            @include OpenSansFont(400, 18px, 11px);
        }
    }

    .image-preview {
        $imageWidth: 40px;
        $actionsWidth: 64px;

        border-radius: 6px;
        border: 1px solid#F0F0F0;
        background:#FAFAFA;
        padding: 8px 16px; 
        display: flex;
        align-items: center;

        .thumbnail {
            display: flex;
            justify-content: center;
            align-items: center;
            width: $imageWidth;
            height: $imageWidth;
            background-color: #E7EFFF; 
            border-radius: 4px; 
            margin-right: $gap / 2;

            .image {
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('../../../../assets/icons/general/panorama.svg');
                width: 24px;
                height: 24px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            width: calc(100% - 92px);

            span {
                @include OpenSansFont(400, 20px, 14px);
                margin-bottom: unset;
            
                &.date {
                    @include OpenSansFont(400, 18px, 11px);
                    color: #8C8C8C;
                }
            }
        }

        .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: $actionsWidth;

            .preview, .remove {
                width: 24px;
                height: 24px;
                background-size: cover;
                background-repeat: no-repeat;
                cursor: pointer;
            }

            .preview {
                background-image: url('../../../../assets/icons/general/eye.svg');
                margin-right: 16px;
            }

            .remove {
                background-image: url('../../../../assets/icons/general/remove.svg');
            }
        }
    }
}
