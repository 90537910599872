.content-item-list-data {
	p {
		margin: 0;
	}

	small {
		display: flex;
		flex-flow: column;
	}
}

.action-buttons {
	@media (min-width: 556px) {
		display: contents;
	}

	@media (max-width: 556px) {
		margin-top: 10px;
	}
}


.content-badge {
	margin-left: 4px;
    padding: 2px 8px;
    border-radius: 8px;
    background-color: rgb(255, 222, 186);
    font-weight: 600;
    font-size: 11px;
}

.automatic-content {
	background-color: rgb(255, 222, 186);
}

.editorial-content {
	background-color: rgb(184, 208, 254);
}

.list-item-title {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;

}

.type-label-placeholder {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.type-label {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

