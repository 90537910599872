// Variables
$dark-gray-background-color: #F0F0F0 ;
$gray-background-color: #FAFAFA ;
$text-gray-color: #434343;

@mixin GeneralWrappers() {
    margin-bottom: 16px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0px 50px 10px rgba(37, 40, 49, 0.06);
    display: flex;
    flex-direction: column;
    padding: calculateIndent(2) 0px;

    & > *:not(.multisport-widget-sport-section-events) {
        margin: 0 calculateIndent(2);
    }
}

// Function
@function calculateIndent($times: 1) {
    @return $times * 8px;
}
