.category-tree {
	.rst__nodeContent {
		right: 0;
	}

	.rst__nodeContent > div {
		height: 100%;
	}

	.rst__rowLabel {
		width: 100%;
		padding-right: 0px !important;
	}

	.rst__row {
		opacity: 1;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	.rst__rowContents {
		flex: auto;
		padding: 0;
		overflow: hidden;
		border-radius: 0;
		box-shadow: none;
	}

	.rst__rowTitle {
		font-weight: normal;
	}

	.rst__rowWrapper {
		padding: 4px 0px 4px 0;
	}

	.ReactVirtualized__Grid {
		outline: none;
	}

	.category-tree-title {
		flex: 2;
		overflow: hidden;
	}

	.category-tree-btns {
		flex: 1;
		max-width: 200px;
	}

	.category-tree-btns,
	.category-tree-title {
		padding: 0.75rem;
	}

	.rst__moveHandle {
		min-width: 44px;
		border-radius: 0px;
		-webkit-box-shadow: 0 0 0 0;
		box-shadow: none;
	}

	@media screen and (max-width: 500px) {
		.rst__node {
			height: 100px !important;
		}

		.category-tree-title {
			padding-left: 0.75rem !important;
		}
	}
}
