.blacklisted {
	border-radius: 20px;
	width: 93px;
}

.non-translatable {
	border-radius: 20px;
	width: 93px;
}

.inactive {
	color: #a6a9ac !important;
}

.video-origin {
	@media screen and (max-width: 1125px) {
		margin-bottom: 5px !important;
	}
}

.display-none {
	@media screen and (max-width: 770px) {
		display: none !important;
	}
}

.assetWraper {
	width: 55px;
}

.round-pic {
	border-radius: 25px;
	max-width: 50px;
	max-height: 50px;
}

.birthdate {
	background-color: transparent;
}

.listing-dropdown-menu {
	right: 0 !important;
	left: auto !important;
	transform: translate3d(0, 38px, 0px) !important;
}
