.live-blog-list {
  margin-bottom: 18px;
  background-color: #F1F3F5;
}

.live-blog-list-item:hover {
    background-color: #D4D4D4;
}

.live-blog-sortable-list-item-image {
  width: fit-content;
  border-radius: 15%;
  height: 80px;
}

.live-blog-modal {
  width: 125%;
}

.sortable-container {
  z-index: 9999;
}

.live-blog-list-item-row {
  width: max-content;
}

.list-group-item {
  & > .row {
    align-items: center;

    .btn-remove-wrapper {
      text-align: right;
    }
  }
}


