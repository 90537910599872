.emoji-button {
	width: 25px;
	height: 25px;
	&:hover {
		box-shadow: inset 0 0 0 1px #fff, 0 0 0 2px #338cff;
		background-color: #f3f3f3;
	}
}

.emoji-container {
	position: absolute;
	top: 0;
	right: 0;
}

.emoji-list {
	position: absolute;
	top: 100%;
	right: 0;
	width: 356px;
	height: 310px;
	flex-direction: column;
	z-index: 1020;
	@media (max-width: 400px) {
		width: 290px;
	}

	.scrollbar-container {
		height: 230px;
		max-height: 230px;
	}
}

.title-input,
.strap-line-input {
	padding-right: 55px;
}
