@import 'variables';

.live-blog-feed {
	display: flex;
	flex-direction: column;
	width: 100%;

	.live-blog-feed-tabs {
		display: inline-flex;
		margin-right: auto;
		margin-bottom: 16px;
		padding: 6px;
		background-color: $tabs-background-color;
		border-radius: 6px;

		.live-blog-feed-tab {
			padding: 6px 12px;
			border: 1px solid transparent;
			cursor: default;
			border-radius: 3px;

			&.selected {
				background-color: $tabs-background-color-selected;
				color: white;
			}

			&:hover:not(.selected) {
				color: $tabs-background-color-selected;
				cursor: pointer;
			}
		}
	}

	.live-blog-feed-pagination {
		margin-top: 16px;
	}
}
