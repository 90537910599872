.test-link-before-sending-notification-wrapper {
	text-align: center;

	i {
		pointer-events: unset;
		cursor: pointer;
		position: relative;
		top: calc(50% - 12px);
	}
}
