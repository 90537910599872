.iframe-container {
	margin: 0 auto;
	max-width: 100%;
	min-width: 300px;
}

.iframe-inner-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
}

.iframe {
	width: 300px;
	min-width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	overflow: hidden;
}
