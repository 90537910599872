.steps-container {
  display: flex;
  margin-bottom: 50px;
  padding: 16px 50px;
}

.step {
  text-align: center;
  flex: 1;
  cursor: default;
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
}

.step .line {
  height: 0px;
  border-width: 1px;
  border-style: solid;
  position: initial;
  top: calc(50% - 10px);
  left: calc(50% + 40px);
  width: calc(100% - 15px);
}

.step .circle {
  padding: 16px 20px;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 12px;
}

.step-active .circle {
  background: #bbb;
  color: #000;
}

.step-done .circle {
  background: #4caf50;
  color: #eee;
}

.step-inactive .circle {
  background: #bbb;
  color: #000;
}

.step-active .label {
  color: #000;
  margin-top: 12px;
  margin-bottom: 25px;
  position: absolute;

}

.step-done .label {
  color: #222;
  margin-top: 12px;
  margin-bottom: 25px;
  position: absolute;
}

.step-inactive .label {
  color: #888;
  margin-top: 12px;
  margin-bottom: 25px;
  position: absolute;
}

.step-done .line {
  border-color: #4caf50;
}

.step-inactive .line, .step-active .line {
  border-color: #bbb;
}

.label:hover {
  cursor: pointer;
}

.name:hover {
  color: #626979;
}
