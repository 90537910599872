// If you want to override variables do it here
@import 'custom/variables';
// Import styles
@import '~@coreui/coreui/scss/coreui.scss';
// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';
// If you want to add something do it here
@import 'blocky';
@import 'custom/border_pulsate';
@import 'custom/helper_classes';
@import 'custom/general';
// ie fixes
@import 'custom/ie-fix';
@import './LoadingSpinners/loading-btn.scss';
@import './LoadingSpinners/loading.scss';
@import 'custom/custom_loading';
@import 'dropzone';

.smp-pagination {
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 14px;
	padding-left: 0;

	.active {
		a {
			color: #fff;
			cursor: default;
			background-color: #337ab7;
			border-color: #337ab7;
		}
	}

	li {
		display: inline-block;
		text-align: -webkit-match-parent;

		a {
			position: relative;
			float: left;
			padding: 6px 12px;
			line-height: 1.42857143;
			color: #337ab7;
			text-decoration: none;
			background-color: #fff;
			border: 1px solid #ddd;
		}
	}

	[role='button'] {
		cursor: pointer;
	}
}
