.dot {
	margin-right: 3px;
	margin-left: 3px;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
}

.entity-total-stat {
	padding: 5px;
}
.entity-total-stats-container {
	margin-left: 0;
	padding-bottom: 10px;
}

.filters-container-stats {
	padding-bottom: 30px;
}

.entity-select {
	width: 50%;
	display: flex;
	flex-flow: column;
}

.analytics-filters {
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.date-time-filter {
	display: flex;
	flex-wrap: wrap;
}

.admin-select {
	width: 100%;
}
