@import '../../../../../scss/custom/media_query';

.tournament-groups-list {
	.tournament-order-item {
		&:nth-child(2n + 1) {
			background-color: #f0f3f5 !important;
		}
	}
}

@include tablet {
	.tournament-groups-list {
		.tournament-group-search-wrapper {
			& > div:first-child {
				max-width: 30%;
			}

			& > div:nth-child(2) {
				max-width: 70%;
			}
		}

		.tournament-order-item {
			& > div {
				margin-bottom: 4px;
			}
		}
	}
}

@include phone {
	.tournament-groups-list {
		.tournament-group-search-wrapper {
			& > div:first-child {
				max-width: unset;
				margin-bottom: 14px;
			}

			& > div:nth-child(2) {
				max-width: unset;
			}
		}
	}
}
