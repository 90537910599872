/**
 * Loading classes
 */

.loading-overlay {
	position: relative;
	z-index: 1;
	min-height: 200px;

	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.9);
	}

	&::after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 50%;
		margin-top: -30px;
		margin-left: -30px;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		border: 5px solid $gray-300;
		border-top-color: $primary;
		animation: customLoading 2s linear infinite;
	}
}

.loading-overlay-h-auto {
	position: relative;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.9);
	}

	&::after {
		content: '';
		position: absolute;
		z-index: 1;
		top: 55%;
		left: 50%;
		margin-top: -30px;
		margin-left: -30px;
		width: 50px;
		height: 50px;
		border-radius: 50px;
		border: 5px solid $gray-300;
		border-top-color: $primary;
		animation: customLoading 2s linear infinite;
	}

	&.with-text {
		&::before {
			content: attr(data-loading-text);
			display: flex;
			align-items: end;
			justify-content: center;
		}

		&::after {
			top: 45%;
		}
	}
}

.loading-overlay-h-fill {
	height: 65px;
}

.loading-overlay-w-fill {
	width: 65px;
}

.loading-overlay-small {
	position: relative;
	z-index: 1;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(255, 255, 255, 0.9);
	}

	&::after {
		content: '';
		position: absolute;
		bottom: -25%;
		left: 100%;
		margin-left: -22px;
		width: 20px;
		height: 20px;
		border-radius: 50px;
		border: 5px solid #c8ced3;
		border-top-color: #20a8d8;
		animation: customLoading 2s linear infinite;
	}
}

@keyframes customLoading {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
