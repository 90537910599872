.option-container {
	.option-logo-player,
	.option-logo-coach,
	.option-logo-team_president {
		border-radius: 15px;
		max-width: 25px;
		margin-right: 10px;
	}
	.option-logo-team,
	.option-logo-tournament,
	.option-logo-venue,
	.option-logo-club {
		max-width: 25px;
		margin-right: 10px;
	}
	.option-label {
		display: inline-block;
	}

	padding: 0 3px 3px 0;
}
