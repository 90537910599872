
@import '../../../../global/fonts.scss';


.designed-button, 
.bubble-displayed-count, 
.custom-dynamic-select-input-wrapper, 
.custom-input-wrapper, 
.general-modal, 
.custom-search-input-wrapper, 
.custom-search-date-wrapper,
.custom-search-input-extended-sport-wrapper,
.custom-select-input-wrapper,
.custom-phone-input-wrapper {
    .error-message {
        color:  #E25160;
        @include OpenSansFont(400, 16px, 11px);
    }

    label {
        margin-bottom: 5px;
    }
}

.custom-select-value-holder,
.custom-search-date-input-wrapper,
.custom-input,
.PhoneInputCountry, .PhoneInputInput {
    border-radius: 6px;
    border: 1px solid hsl(0,0%,80%);
}

