.article-lock-overlay-message {
	position: sticky;
	top: 55px;
	margin-bottom: 16px;
	border: 1px solid #ffecb5;
	padding: 16px 24px 16px 24px;
	background-color: #fff3cd;
	z-index: 111;

	@media (max-width: '550px') {
		top: 82px;
	}

	@media (max-width: '348px') {
		top: 94px;
	}

	.fa {
		margin-right: 8px;
	}
}

.article-lock-overlay-prompt {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	padding: 8px;
	border-radius: 4px;

	button {
		margin-top: 8px;
	}
}

.article-edit-wrapper.with-lock-overlay {
	filter: grayscale(100%);
	opacity: 0.6;
	pointer-events: none;

	#sidebar-elements-edit-mode {
		display: none;
	}

	* {
		pointer-events: none !important;
	}
}

.article-locked {
	&.app-body {
		overflow: unset;
	}

	&.breadcrumb {
		margin-bottom: 16px;
	}
}

.article-lock-warning,
.article-lock-warning .Toastify__close-button {
	color: black;
}
