.option-container {
	display: flex;
	align-items: center;

	.option-logo {
		border-radius: 50%;
		max-width: 25px;
		max-height: 25px;
		margin-right: 10px;
	}

	.option-label {
		display: flex;
	}
}
