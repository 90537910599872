.social-media-container {
	.form-control:disabled {
		background-color: white !important;
		opacity: 1 !important;
	}

	.social-media-button {
		width: 36px;
		height: 100%;
		outline: none !important;
	}

	.disabled {
		pointer-events: none;
	}
}
