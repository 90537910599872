.min-w-500 {
	min-width: 500px;
}

.min-h-500 {
	min-height: 500px;
}

.dashboard-user-content {
	#new-content-article,
	#new-content-gallery,
	#new-content-video {
		display: none;
	}
	.smp-pagination {
		display: none;
	}
	.card-header {
		text-align: left;
	}

	.w-title {
		width: 30%;
		text-align: left;
	}
	table {
		margin: 0;
		border-color: transparent !important;
	}
}
