@keyframes border-pulsate-right {
	0% {
		border-right-color: rgba(77, 189, 116, 1);
	}
	50% {
		border-right-color: rgba(77, 189, 116, 0);
	}
	100% {
		border-right-color: rgba(77, 189, 116, 1);
	}
}

.border-pulsate-right {
	border-right: 5px solid;
	animation: border-pulsate-right 2s infinite;
}

@keyframes border-pulsate-left {
	0% {
		border-left-color: rgba(77, 189, 116, 1);
	}
	50% {
		border-left-color: rgba(77, 189, 116, 0);
	}
	100% {
		border-left-color: rgba(77, 189, 116, 1);
	}
}

.border-pulsate-left {
	border-left: 5px solid;
	animation: border-pulsate-left 2s infinite;
}

@keyframes border-pulsate-bottom {
	0% {
		border-bottom-color: rgba(77, 189, 116, 1);
	}
	50% {
		border-bottom-color: rgba(77, 189, 116, 0);
	}
	100% {
		border-bottom-color: rgba(77, 189, 116, 1);
	}
}

.border-pulsate-bottom {
	border-bottom: 5px solid;
	animation: border-pulsate-bottom 2s infinite;
}

@keyframes border-pulsate-top {
	0% {
		border-top-color: rgba(77, 189, 116, 1);
	}
	50% {
		border-top-color: rgba(77, 189, 116, 0);
	}
	100% {
		border-top-color: rgba(77, 189, 116, 1);
	}
}

.border-pulsate-top {
	border-top: 5px solid;
	animation: border-pulsate-top 2s infinite;
}

@keyframes border-pulsate-all {
	0% {
		border-color: rgba(77, 189, 116, 1);
	}
	50% {
		border-color: rgba(77, 189, 116, 0);
	}
	100% {
		border-color: rgba(77, 189, 116, 1);
	}
}

.border-pulsate-all {
	border: 5px solid;
	animation: border-pulsate-all 2s infinite;
}
