.loader-edit-tournaments-group {
	position: absolute;
	top: 50%;
	right: 45%;
}

@media (max-width: 1000px) {
	.loader-edit-tournaments-group {
		right: 50%;
	}
}
