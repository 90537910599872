.table-config-container {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-template-rows: repeat(10, 1fr);
	gap: 2px;

	.table-config-square {
		width: 16px;
		height: 16px;
		border: 1px solid rgb(107, 159, 177);
		border-radius: 2px;

		&.highlighted {
			background-color: aliceblue;
		}

		&.selected {
			background-color: lightblue;
		}
	}
}
