.status-pill {
  display: inline-flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;

  font-family: "Open Sans", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #000;
}
