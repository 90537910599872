.embed-video-code-preview,
.embed-code-preview {
	max-width: 540px;
}
.embed-code-preview-short {
	max-height: 250px;
	max-width: 540px;
	overflow: hidden;
}

@media screen and (max-width: 400px) {
	.embed-code-preview,
	.embed-code-preview-short {
		.instagram-media {
			min-width: 270px !important;
		}
	}
}
