.image-container {
	position: relative;
	text-align: center;

	.image-footer {
		width: 100%;
		position: absolute;
		bottom: 0;

		.image-description {
			background-color: rgba(0, 0, 0, 0.8);
			color: #ffffff;
		}

		.actions-wrapper {
			left: 0;

			.btn {
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				border-radius: 0;
				&:first-child {
					border-bottom-left-radius: 4px;
				}
				&:last-child {
					border-bottom-right-radius: 4px;
				}
			}
		}
	}

	.gallery-image-footer {
		width: 100%;
		position: absolute;
		bottom: -15px;

		.image-description {
			background-color: rgba(0, 0, 0, 0.8);
			color: #ffffff;
		}

		.actions-wrapper {
			left: 0;

			.btn {
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				border-radius: 0;
				&:first-child {
					border-bottom-left-radius: 4px;
				}
				&:last-child {
					border-bottom-right-radius: 4px;
				}
			}
		}
	}
}
