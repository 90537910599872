.custom-tabs-container {
  .tab-content {
    border: #F5F5F5;
    border-radius: 0 0 8px 8px;
    background: #F5F5F5;
  }

  .tab-pane {
    padding: 24px;
    margin-bottom: 16px;
  }

  .nav-tabs .nav-link {
    background: #D9D9D9;
    border: none;
  }


    .nav-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 25%;
    max-width: 25%;
    height: 42px;
    background: #D9D9D9;
    box-sizing: border-box; // Includes padding and border in width calculation
    padding: 0 10px;
    cursor: pointer;
    border-bottom: 1px solid #BFBFBF;
    border-right: 1px solid #BFBFBF;
    border-top: 1px solid #BFBFBF;


    &:first-child {
      border-radius: 8px 0 0 0;
    }

    // Only the 4th tab in the first row has a rounded top-right corner
    &:nth-child(4) {
      border-radius: 0 8px 0 0; // Rounded top-right corner for the 4th tab
    }

    // All other tabs do not need a border-radius, including last tabs in other rows
    &:nth-child(4n + 4):not(:nth-child(4)) {
      border-radius: 0; // Remove any rounded corners for subsequent 4th tabs in other rows
    }

    // First tab in each row gets no left border
    &:nth-child(4n + 1) {
      border-left: none;
    }

    // Remove bottom border for the tabs in the last row
    &:nth-last-child(-n + 4) {
      border-bottom: none; // No bottom border for the last row of tabs
    }

    .nav-link {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      color: #000;
    }

    .nav-link.active {
      background: #F5F5F5;
      border-radius: 8px 0 0 0;
      border: none;
    }
  }

  .custom-tab-label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    max-width: 100%;

  }
}

.custom-checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  width: 35%;

  .checkbox {
    width: 32px;
    height: 32px;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF;
  }

  .checkbox.checked {
    background-color: #007bff;
  }

  .checkmark {
    color: white;
    font-size: 16px;
    line-height: 1;
  }

  .checkbox-label {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .disabled-label {
    color: #8C8C8C;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.custom-toggle-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 16px;
  width: 35%;
}

.toggle-icon {
  width: 42px;
  height: 24px;

  img {
    width: 100%;
    height: 100%;
  }
}

.custom-label {
  color: #0F172A;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}



