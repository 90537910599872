@import '../../../../../../scss/custom/media_query';

.tournament-list-group {
	margin-bottom: 18px;

	.list-group-item {
		& > .row {
			align-items: center;

			.btn-remove-wrapper {
				text-align: right;
			}
		}
	}
}

@include tablet {
	.tournament-list-group {
		.list-group-item {
			margin-bottom: 14px;

			& > .row {
				gap: 20px;

				.btn-remove-wrapper {
					text-align: center;
				}
			}
		}
	}
}
