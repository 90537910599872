.dropzone-custom-class {
  background: transparent;
  border: dotted #1da1f2;
  border-radius: 20px;
  overflow: hidden;

  .custom-height {
    height: 263px;

  }
}

.dropzone-button-custom-class {
  .dz-preview {
    display: none !important;
  }

  .upload-image-button {
    background-color: #d0cbcb;
    color: #0F172A;
    border: none;
    width: 100%;
  }

  .upload-image-button:hover {
    background-color: #0F172A;
    color: #e5e7eb;
  }

  .browse-btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.edit-image-button {
  background-color: #d0cbcb;
  color: #0F172A;
  border: none;
  max-width: 100%;
}

.crop-image-button {
  background-color: #d0cbcb;
  color: #0F172A;
  border: none;
  max-width: 100%;
}

.image-operations-container {
  flex-direction: row;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.image-operations-buttons {
  @media screen and (max-width: 1096px) {
    margin-top: 1%;
  }
}

.image-processing-container {
  position: relative;

  .image-uploading-loader-container {
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;

    .row-loader {
      position: absolute;
      top: 30%;
      width: 100%;
    }

    .row-loader-text {
      position: absolute;
      top: 45%;
      width: 100%;
    }
  }

  .hide-image-loader {
    display: none;
  }
}

@media (max-width: 800px) {
  .live-blog-masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }
}

.live-blog-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  //margin-left: 40px; /* gutter size offset */
  margin-top: 20px; /* gutter size offset */
  width: auto;
  height: auto;
}
.live-blog-grid-column {
  margin-left: 5px;
  padding-left: 5px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.live-blog-grid-column > img {
  background: grey;
  margin-bottom: 50px;
}

.image-cell {
  max-width: calc(20% - 4px);
  @media (max-width: 790px) {
    max-width: calc(33% - 4px);
  }
}

.events-label {
  margin-left: 10%;
}

.events-button {
  margin-left: 10%;
}


