.live-blog-editorial-admin-new-post-container {
	display: flex;
	flex-direction: column;

	.live-blog-editorial-admin-new-post-publish-button {
		margin-right: auto;
	}

	.live-blog-editorial-admin-new-post-footer {
		display: flex;
		justify-content: center;
		margin-top: 64px;

		& > button {
			min-width: 100px;
			margin-right: 16px;
		}
	}
}
