@import 'variables';

$config-option-height: 38px;

.new-post-config-container {
	margin-top: 16px;

	.new-post-config-time {
		border: 1px solid $time-config-border-color;
		border-left: none;
		border-right: none;
		padding: 8px;
		background-color: $time-config-background-color;

		& > div {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		button {
			display: flex;
			align-items: center;
			height: 24px;
			padding: 4px 8px;
		}

		.flatpickr-input {
			border: 1px solid lightgray;
		}
	}

	.toggle-editor {
		display: flex;
		align-items: center;
		margin: 16px 0;
		min-height: $config-option-height;

		.fa {
			pointer-events: unset;
			margin-right: 8px;

			&.fa-toggle-on {
				color: $success;
			}
		}

		& > input,
		& > .select {
			margin-left: auto;
			width: 250px;
			height: 100%;
		}
	}
}
