.cursor-pointer {
	cursor: pointer;
}

.cursor-draggable {
	cursor: grab;
}

.cursor-dragging {
	cursor: grabbing;
}

.max-width-50 {
	max-width: 50px;
}

.max-width-100 {
	max-width: 100px;
}

.max-width-150 {
	max-width: 150px;
}

.max-width-200 {
	max-width: 200px;
}

.max-width-300 {
	max-width: 300px;
}

.min-width-100 {
	min-width: 100px;
}

.min-width-200 {
	min-width: 200px;
}

.min-width-300 {
	min-width: 300px;
}

.top-0 {
	top: 0;
}

.bottom-0 {
	bottom: 0;
}

.right-0 {
	right: 0;
}

.left-0 {
	left: 0;
}
