.overridden-content-item {
	.overriding-actions {
		top: 0;
		right: 0;
		.btn {
			border-top-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}
