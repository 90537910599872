@import '../../../../global/fonts.scss';

.bubble-displayed-count {
    display: flex;
    width: 25px;
    height: 20px;
    padding: 2px 6px; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    border-radius:  9999px;
    background: #4CAF50; 
    color: white;
    @include OpenSansFont(600, 16px, 11px);

    &.neutral {
        background: #BFBFBF;
    }
}