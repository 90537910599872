.gallery-item {
	position: relative;
	.item-image {
		width: 100%;
		max-width: 250px;
	}

	.delete-button {
		position: absolute;
		top: 0px;
		right: -4px;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		border-bottom-right-radius: 0;
	}
}

.gallery-item-update-image {
	position: relative;
	.delete-button {
		font-size: 8px;
		padding: 1px 2px;
		position: absolute;
		top: 0;
		right: 0;
	}
}
