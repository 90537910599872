.tip-message {
	color: #9f7f24 !important;
}

.tip-container {
	background-color: #f8f6e8;
}

.tip-message-color {
	background-color: #0c0e10;
}
