.divider-configuration {
  border-left: 2px solid #cac5c5;
  height: 90%;
  position:absolute;
  left: 53%;
}

.list-present-in {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 8%;
  background-color: #F1F3F5;
}


.live-blog-container {
  min-height: 650PX;
}

.loader-edit-live-blog {
  position: absolute;
  top: 50%;
  right: 45%;
}

@media (max-width: 1000px) {
  .loader-edit-live-blog {
    right: 50%;
  }
}

.container {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
}

.authors {
  max-width: 85%;
  margin-left: 10%;
}

.status {
  max-width: 85%;
  margin-left: 10%;
  z-index: 102;
  position: relative;
}

.tournament {
  z-index: 101;
  position: relative;
}

.teams {
  z-index: 100;
  position: relative;
}

.author {
  z-index: 101;
  position: relative;
}


.language {
  z-index: 102;
  position: relative;
}

.type {
  max-width: 85%;
  margin-left: 10%;
  z-index: 103;
  position: relative;
}

.match-header {
  max-width: 85%;
  margin-left: 10%;
  z-index: 101;
  position: relative;
}


.main-media-type {
  max-width: 85%;
  margin-left: 10%;
  z-index: 101;
  position: relative;
}

.category {
  z-index: 101;
  position: relative;
}

.additional-categories {
  z-index: 100;
  position: relative;
}

.sponsors {
  z-index: 99;
  position: relative;
}

.main-media-label {
  max-width: 85%;
  margin-left: 11%;
  margin-top: 10%;
}

.live-blog-image-container {
  max-width: 85%;
  margin-left: 10%;
}

.live-blog-buttons {
  float: left;
  margin-bottom: 10%;
  margin-left: 3px;
  margin-top: 1%;
}

.live-blog-type {
  @media screen and (max-width: 1125px) {
    margin-bottom: 5px !important;
  }
}

.list-label {
  margin-left: 10%;
}

.list-button {
  margin-left: 10%;
}
