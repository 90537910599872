@import 'variables';

.content-blocks-selection-wrapper {
	position: relative;
	border: 1px solid $editor-border-color;
	border-top: none;
	border-radius: 0 0 8px 8px;
	padding: 16px;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-auto-rows: 40px;
	gap: 8px;

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 16px;
		height: 1px;
		top: -1px;
		background-color: white;
	}

	&::before {
		left: 0;
	}

	&::after {
		right: 0;
	}

	& > div {
		button {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 0 !important;
			border-color: $editor-border-color;
			border-radius: 8px;
			width: 100%;
			height: 100%;
			max-height: unset;

			&:disabled {
				pointer-events: none;
			}

			& > i {
				display: block;
				font-size: 16px;
			}

			& > div {
				width: 100%;
				font-size: 9px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}
	}
}
