.row-notifications-pill {
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 44px;
  background: #7DB043;
  height: 16px;

  .row-notifications-label {
    color: #252831;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 8px;
    align-self: stretch;
  }
}
