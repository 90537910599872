.custom-version-label {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #D5071C;
}

.validation-border {
  border: 2px solid #D5071C !important;  
}


