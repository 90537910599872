.ld-ext-right,
.ld-ext-left,
.ld-ext-bottom,
.ld-ext-top,
.ld-over,
.ld-over-inverse,
.ld-over-full,
.ld-over-full-inverse {
	position: relative;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	transition-timing-function: ease-in;
	overflow: hidden;
}
.ld-ext-right > .ld,
.ld-ext-left > .ld,
.ld-ext-bottom > .ld,
.ld-ext-top > .ld,
.ld-over > .ld,
.ld-over-inverse > .ld,
.ld-over-full > .ld,
.ld-over-full-inverse > .ld {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -0.5em;
	opacity: 0;
	z-index: -100;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	transition-timing-function: ease-in;
}
.ld-ext-right.running > .ld,
.ld-ext-left.running > .ld,
.ld-ext-bottom.running > .ld,
.ld-ext-top.running > .ld,
.ld-over.running > .ld,
.ld-over-inverse.running > .ld,
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld {
	opacity: 1;
	z-index: auto;
}
.ld-ext-right.running {
	padding-right: 2.5em !important;
}
.ld-ext-right > .ld {
	top: 50%;
	left: auto;
	right: 1em;
}
.ld-ext-left.running {
	padding-left: 2.5em !important;
}
.ld-ext-left > .ld {
	top: 50%;
	right: auto;
	left: 1em;
}
.ld-ext-bottom.running {
	padding-bottom: 2.5em !important;
}
.ld-ext-bottom > .ld {
	top: auto;
	left: 50%;
	bottom: 1em;
}
.ld-ext-top.running {
	padding-top: 2.5em !important;
}
.ld-ext-top > .ld {
	bottom: auto;
	left: 50%;
	top: 1em;
}
.ld-over,
.ld-over-inverse,
.ld-over-full,
.ld-over-full-inverse {
	overflow: hidden;
}
.ld-over.running > .ld,
.ld-over-inverse.running > .ld,
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld {
	z-index: 99999;
}
.ld-over:before,
.ld-over-inverse:before,
.ld-over-full:before,
.ld-over-full-inverse:before {
	content: ' ';
	display: block;
	opacity: 0;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	transition-timing-function: ease-in;
	background: rgba(240, 240, 240, 0.8);
}
.ld-over-full > .ld,
.ld-over-full-inverse > .ld {
	position: fixed;
}
.ld-over-full > .ld {
	color: rgba(0, 0, 0, 0.8);
}
.ld-over-full:before,
.ld-over-full-inverse:before {
	z-index: -1;
	position: fixed;
	background: rgba(255, 255, 255, 0.8);
}
.ld-over.running > .ld,
.ld-over-inverse.running > .ld,
.ld-over-full.running > .ld,
.ld-over-full-inverse.running > .ld {
	z-index: 999999;
}
.ld-over.running:before,
.ld-over-inverse.running:before,
.ld-over-full.running:before,
.ld-over-full-inverse.running:before {
	opacity: 1;
	z-index: 999998;
	display: block;
}
.ld-over-inverse > .ld {
	color: rgba(255, 255, 255, 0.8);
}
.ld-over-inverse:before {
	background: rgba(0, 0, 0, 0.6);
}
.ld-over-full-inverse > .ld {
	color: rgba(255, 255, 255, 0.8);
}
.ld-over-full-inverse:before {
	background: rgba(0, 0, 0, 0.6);
}
