.live-blog-main-image {
  border-radius: 15%;
  position: relative;
  display: block;
  z-index: 10;
}

.live-blog-image {
  text-align: -webkit-center;

}
