.ck-content {
	background-color: #f0f3f5 !important;
	border: 1px solid #c8ced3 !important;
}

.ck-link-form {
	.ck-list {
		li:nth-child(2) {
			display: none;
		}

		li:nth-child(4) {
			display: none;
		}
	}
}
