.search-options-published-date {
	background-color: transparent;
	padding-left: 0;
}

.search-options-container {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #F5F5F5;

}

.search-options-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.search-options-right {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
}

.fu-search-options-label {
  color: #8C8C8C;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}

.search-options-type-label {
  color: #0F172A;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
