.date-picker {
	line-height: 1.7;
}

@media screen and (max-width: 800px) {
	.select,
	.title {
		min-width: 100%;
	}
}
