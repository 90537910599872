@mixin border_suggested_entities($color) {
	border-bottom: 2px solid $color;
	border-left: 2px solid $color;
}

.linked-entity-pill {
	display: inline-block;
	font-size: 12px;
	border-radius: 5px;
	border: 1px solid #e3e3e3;
	overflow: hidden;
	padding-left: 5px;
	cursor: pointer;

	.linked-entity-pill-name {
		display: inline-block;
	}

	.linked-entity-pill-remove {
		width: 20px;
		height: 100%;
		display: inline-block;
		background-color: #e3e3e3;
		color: #575b5e;
		text-align: center;
	}
}

.tournament-border, .competition-border {
	border-left: 2px solid #adccf7;
	border-bottom: 2px solid #adccf7;
}

.player-border {
	border-left: 2px solid #27db3f;
	border-bottom: 2px solid #27db3f;
}

.team-border {
	border-left: 2px solid #fc1111;
	border-bottom: 2px solid #fc1111;
}

.coach-border {
	border-left: 2px solid #215bac;
	border-bottom: 2px solid #215bac;
}

.team_president-border {
	border-left: 2px solid #ffea2c;
	border-bottom: 2px solid #ffea2c;
}

.venue-border {
	border-left: 2px solid #000000;
	border-bottom: 2px solid #000000;
}

.custom-entities-select-sidebar, .sport-entities-select-sidebar {
	font-family: "Open Sans";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;

	img {
		margin-right: 8px;
	}

	.option-container-dropdown {
		cursor: pointer;
		height: 52px;
		font-size: 14px;
		padding: 8px 16px;

		&:hover {
			background-color: #2584ff;
			color: white;

			span,label {
				color: white;
			} 
		}

		.option-label-additional {
			font-size: 11px;
			line-height: 16px;
			color: #8C8C8C;
		}
	}
}

.custom-select-input-wrapper:has(#sidebar-tags-domain-select) {
	.custom-select-options {
 		z-index: 3 !important;
	}  
}

.suggested-entities-container {
	margin: auto 0;

	.label, .sublabel {
		cursor: unset !important;
		font-family: "Open Sans";
		font-style: normal;
		font-weight: 400;
	}

	.label {
		color: #000000;
		font-size: 14px;
		margin-right: 4px;
	}

	.sublabel {
		text-transform: lowercase;
		color: #8C8C8C;
		font-size: 11px;
		margin: auto 0
	}

	.suggested-entities-holder {
		margin: 0.5rem 0 auto;
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		gap: 8px;

		.suggested-entity {
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 32px;
			border: 1px solid #000;
			border-radius: 4px;
			padding: 4px 8px;

			&.suggested-entity-person {
				@include border_suggested_entities(#205BAC);
			}

			&.suggested-entity-organization {
				@include border_suggested_entities(#D800D8);
			}

			&.suggested-entity-place {
				@include border_suggested_entities(#20AC80);
			}

			&.suggested-entity-team {
				@include border_suggested_entities(#fc1111);
			}

			&.suggested-entity-player {
				@include border_suggested_entities(#27db3f);
			}

			&.suggested-entity-tournament, &.suggested-entity-competition {
				@include border_suggested_entities(#adccf7);
			}

			&.suggested-entity-coach {
				@include border_suggested_entities(#215bac);
			}

			&.suggested-entity-venue {
				@include border_suggested_entities(#000000);
			}

			&.suggested-entity-club {
				@include border_suggested_entities(#fc1111);
			}

			.option-container {
				.option-logo-competition, img  {
					margin-right: 4px;
				}
			}
		}

		.remove-button {
			color: #D9D9D9;
			width: 16px;
			height: 16px;
			display: flex;
			justify-content: flex-end;
			align-items: center;	
			margin-top: -2.5px;	
			font-weight: bolder;

			&:hover {
				color: red;
			}
		}
		
	}
}