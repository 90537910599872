.feed-container {
	background-color: #fff;
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 470px;
	position: fixed;
	z-index: 1019;
	height: calc(100vh - 55px);
	left: calc(100% - 50px);
	transform: translateX(calc(-420px));
	right: 0;
	top: 55px;
	overflow-y: auto;
	transition: all 0.25s ease-in-out;
	&.shown {
		transform: translateX(0);
	}
	@media (max-width: '1024px') {
		display: none;
	}
}

.lazy-load-item-container {
	.is-visible {
		height: auto !important;
	}
}

.post-card {
	position: relative;
	transition: all 0.3s ease;

	iframe {
		max-width: 350px;
		max-height: 520px;
	}
	.name {
		text-transform: capitalize;
		font-weight: bold;
	}
	.post-buttons {
		display: flex;
		justify-content: space-around;
		align-items: center;
		transition: all 0.25s ease-in-out;
		overflow: hidden;
		background: rgba(240, 243, 245, 0.9);
		height: 40px;
		font-size: 11px;
		cursor: pointer;
		a {
			text-decoration: none;
			color: inherit;
		}
		i {
			color: black;
		}
	}
}

.source-name {
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	font-size: 10px;
}

.source-thumbnail {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	text-align: center;
	margin: 10px 5px;
	cursor: pointer;
	opacity: 0.6;
	text-transform: capitalize;
	font-weight: bold;

	.image-circle {
		border-radius: 50%;
	}

	img {
		text-indent: -9999px;
		max-height: 40px;
	}

	.type {
		position: absolute;
		font-size: 10px;
		width: 16px;
		line-height: 16px;
		right: -5px;
		top: calc(100% - 10px);
		background-color: #fff;
		border-radius: 50%;
	}

	.notifications {
		position: absolute;
		font-size: 9px;
		width: 16px;
		line-height: 16px;
		left: -3px;
		top: -5px;
		background-color: rgb(255, 136, 0);
		color: #fff;
		border-radius: 50%;
	}

	&.selected {
		color: #0279b3;
		background-color: rgba(174, 220, 243, 0.4);
		opacity: 1;
	}
}

.toggle-feeds-btn {
	width: 50px;
	text-align: center;
	line-height: 50px;
	font-size: 18px;
	font-weight: bold;
	color: #73818f;
	cursor: pointer;
}

.card-tw {
	background-color: rgba(53, 120, 229, 0.6);
	color: #fff;
}

.card-fb {
	background-color: rgba(29, 160, 242, 0.6);
	color: #fff;
}

.embed-code {
	max-width: 350px;
	margin: 0 auto 10px auto;
}

.rss-item-content {
	h4 {
		font-size: 14px;
	}
	p {
		color: #6c757d;
		font-size: 12px;
	}
}

.rss-item-image,
.rss-item-source-image {
	max-height: 198px;
	max-width: 100%;
	min-height: 16px;
	margin-bottom: 5px;
	border-radius: 5px;
	position: relative;
	text-indent: -900000px;
	&:after {
		content: '\f1c5';
		font-size: 16px;
		font-family: 'FontAwesome', sans-serif;
		color: #fff;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;
	}
}

.rss-item-source-image {
	max-width: 100px;
	max-height: 30px;
}

.fa-facebook:before {
	color: #4267b2;
}

.fa-twitter:before {
	color: #1da1f2;
}

.fa-rss:before {
	color: #ee802f;
}

.fa-youtube-play:before {
	color: #ff0000;
}

.fa-instagram {
	background: #d6249f;
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}
