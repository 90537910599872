$modalWidth: 516px;

.general-modal-background {
    position: fixed;
    z-index: 1;
    opacity: 0.4;
    background: #000;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

    .general-modal {
        position: fixed; /* Stay in place */
        z-index: 2; /* Sit on top */
        width:$modalWidth; /* Full width */
        position: fixed;
        top: 50%;
        left: 50%;
        opacity: 1.4;
        transform: translate(-50%, -50%);
        border-radius:  6px;
        background: #FFF; 
        padding: 24px;
    
        #header {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 16px;
    
           h3 {
                display: inline-block;
            } 
    
            .close-header-btn {
                cursor: pointer;
                width: 16px;
                height: 16px;
                background-repeat: no-repeat;
                margin-bottom: 16px;
            }
        }
    
        #general-modal-action-buttons {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
        }
    }

