.canonical-url-info {
  display: inline-flex;
  align-items: center;

  .info-icon {
    background-image: url('../../../../../assets/icons/general/info.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: 4px;
    position: absolute;
    top: 1px;
    line-height: 20px;
    filter: invert(44%) sepia(97%) saturate(5732%) hue-rotate(214deg) brightness(99%) contrast(97%);
  }

  .info-message {
    border-radius: 4px;
    background: #FFF;
    color: #000;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 0 8px;
    max-width: 275px;
    position: absolute;
    z-index: 1;
    top: 22px;

    .info-message-title {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      border-bottom: 1px solid #F0F0F0;
      min-height: 36px;
      padding: 8px 0;
    }

    .info-message-description {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      padding: 8px 0;
    }
  }
}

