.w-220 {
	width: 305px !important;
}

.h-38 {
	height: 38px;
}

.predefined-date {
	height: 38px;
	padding-left: 10px;
	padding-right: 10px;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid #cccccc;
	margin-right: 5px;
	cursor: pointer;

	&:hover {
		background-color: #e5e5e5;
	}
}

.selected {
	border: 1px solid #0390e1;
	background-color: rgba(78, 196, 247, 0.24);
}
