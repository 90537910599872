.fa {
	pointer-events: none;
}

$positions: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20;

.formation-select {
	width: 230px;
}

@media (max-width: 1280px) {
	.formation-container {
		justify-content: center;
	}

	.formation-select {
		width: 300px;
	}
}

.team-formation-wrapper {
	position: relative;
	width: 19vw;

	@media (max-width: 1280px) {
		margin: 0 auto;
		min-width: 300px;

		@each $position in $positions {
			@each $position1 in $positions {
				.x-#{$position}-y-#{$position1} {
					position: absolute;
					background-color: rgba(255, 255, 255, 0.8);
					border-radius: 50%;
					height: 30px;
					width: 30px;
					bottom: #{$position * 18}px;
					right: #{$position1 * 27}px;
					padding: 2px;
				}
			}
		}
	}

	@media (min-width: 1280px) {
		margin-left: auto;

		@each $position in $positions {
			@each $position1 in $positions {
				.x-#{$position}-y-#{$position1} {
					position: absolute;
					background-color: rgba(255, 255, 255, 0.8);
					border-radius: 50%;
					height: 30px;
					width: 30px;
					bottom: #{$position + $position * 0.1}vw;
					right: #{$position1 * 1.7}vw;
					padding: 2px;
				}
			}
		}
	}

	.lineup-shirt {
		position: absolute;
		text-align: center;
		left: -5px;
		bottom: -5px;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		font-size: 10px;
		font-weight: bold;
		color: #fff;
		background-color: rgb(168, 0, 0);
	}
}

.t-shirt {
	text-align: center;
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	min-width: 28px;
	background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAnCAMAAAB6+uurAAAAAXNSR0IB2cksfwAAAAlwSFlzAAASdAAAEnQB3mYfeAAAAhZQTFRFAAAAnp6enp6enp6enp6enp6enp6enp6enZ2dnp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enZ2dnp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6empycnp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enJ2dnp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6enp6ecHuIcn+McHyInp6enp6enp6enp6enp6enp6ebnqFcX6McX2KZW9zb3qGcoCNc4GPcoCNbnqFnp6enp6ea3WAcX+Mc4GPcHyJcX6LbnuGnp6enp6ea3V/cX6Mcn+NaXN7cn+Mcn+NbniEnp6enp6enp6ea3R/cX6LcoCObnqGbnmEcn+Mc4GPcoCNb3uInp6enp6enp6ebniEcoCOb3yIbXiCcn+Mcn+Nb3uHnp6ecX6LcoCOb3uHcX6McoCNbnqGnp6enp6enp6enp6enp6enp6eanN+cn+McoCNb3uHbXiDcn+Ncn+NnZ6enp6enp6enp6ebXd/b3uHbniDcX6McHyJnp6eb3qGcoCNb3uHbniEc4GOcH2JZGx2coCObnmEb3uHb3uHb3uHnp6e/5a66gAAALJ0Uk5TAFq2mRAGhnQCU/P/pwyJ/HYBWfnSTgI8vf56BO2rgIr3wHlRT3Gyk5z7e38DB9PrFQBY+v2DXosEbvWHZwFcBXiWhEmRbYhwGep9d5c0MsgxHdaCXds1EaloACfi/+EeDZIGrvpmliXXGgit3wjA0Rgv3+8Dmeo2D7f+3Sck4TsM5DARvMsnM5TlPLfcICrd8vHnQAau6DAQwc0ay8ofBTcPuF/JI+0yD/prAekNLSAfW5v8Zy8AAAG6SURBVHicY2CAA0YmCGBmQYixskEF2TkYMAEnFzcU8PDCdfDBxPgFBDF0CAlzw4GIqBhYTFxCEi4mKSWNpoNRBigsKycnD7ZLQVFJWUVVDcwWVpeT09AEimmh2sMItENSW4eBQVePGw3oGzAwGBoZc3ObmIoh6TAD6TC3sJSRsULXATReRsZaxwaoR1LOFq7DDuQPbXsHY0z1EGDiqGMEdJuJE8xtziA7XMRcceoAKnYzdAWpcodq8fDklvTS8catAQR8GIQ0uRV8wRr8/AMCg4J1XSXxa+F2Yw0JDQuPiIxiiI6JjYtPEMPtD7jbEnV4k5JjU1IZ0mJjY+PSEzII6QCGdmZWNlBxDkNuHpDKLygsIqSjWLSkFKi0rJyBoaKyKja2uqa2joCO+oYIoI7GJpD/m1uAzNa2drx6ikUbOoDKOrsggVzRDeT09Pb1T8ClYeKkyVOmAh0zbTos9mfMBOqZFT97Di4tc+fNB9lRtgCRxhYuAgosZliCS8sShqXLYmOXLUdOyStaVq5ajU8Lw/Q1jSg6GBjWrotiwKuFYf0GjGzJQEALdjCqZVTLqBaaatmYiAPYIasCAP4xp7ZcdKpaAAAAAElFTkSuQmCC)
		no-repeat center center;
	background-size: contain;
}

.score-select > div {
	border-color: rgb(255, 255, 255) !important;
}

.round-select > div {
	border-color: rgb(253, 1, 1) !important;
}

.match-teams-color-picker-wrapper {
	position: relative;
	margin: 30px 0 15px;

	.popover {
		left: 0;
	}
}
