.scoped-dropzone-style {
	.dz-preview {
		display: none !important;
	}

	.browse-btn {
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
}
